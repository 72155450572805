import { useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MySelect } from "../../ui/controls/MySelect";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { onlyIntegers } from "../../../helpers/numericFields";
import { addFile } from "../../../store/slices/files/thunks";
import { addEvent } from "../../../store/slices/catalogue/thunks";


export const AddEvent = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();


  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Crear Evento
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            subject: '',
            concept: '',
            time: '',
            names: '',
            topic: "",
            address: "",
            contact: "",
            operator: "",
            date: '',
            notes: '',
          }}
          onSubmit={(({ subject, concept, time, names, topic, address, contact, operator, date, notes },
            { resetForm, setErrors }) => {

            if (!date) {
              return setErrors({ "date": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addEvent(subject, concept, time, names, topic, address, contact, operator, 
              date.year + "-" + (date.monthIndex + 1) + "-" + date.day, notes, success));

          })}
          validationSchema={
            Yup.object({
              subject: Yup.string()
                .required("Requerido"),
              concept: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Tipo de evento:*' name='subject' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Concepto:*' name='concept' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Horario:' name='time' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombres:' name='names' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Tema:' name='topic' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Direccion:' name='address' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='¿Con quién es la cita?' name='contact' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Operadora' name='operator' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha:*' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={3} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
