import { createSlice } from '@reduxjs/toolkit';

export const groupSlice = createSlice({
  name: 'group',
  initialState: {    
    info: {
      group2:"",      
      masterSale: {      
        rooms: [],
        services: [],
        advancePayments: [],
        rejectedPayments: [],        
      },
      individualFiles: [],
      allFiles: [],
      hotels: [],
      peopleSummary: {
        adults: 0,
        juniors: 0,
        minors: 0,
        infants: 0,
      }
    },    
    guestList: [],
    weedingPassList: [],
    courtshipLine: [],
    seatingPlan: [],    
    fullGuestList: [],  
    guestHighlighted: 0,
    transportRequests:[],
    transportChurch:[],
    hotelImages:[],
    fullWeedingPassList: [],
    externalGuestHighlighted: 0,
    specialRequests:[],
  },
  reducers: {   
    setInfo: (state, action) => {
      state.info = action.payload;
    },   
    setCourtshipLine: (state, action) => {
      state.courtshipLine = action.payload;
    },
    setSeatingPlan: (state, action) => {
      state.seatingPlan = action.payload;
    },
    setGuestList: (state, action) => {
      state.guestList = action.payload;
    }, 
    setFullGuestList: (state, action) => {
      state.fullGuestList = action.payload;
    },   
    setGuestHighlighted: (state, action) => {
      state.guestHighlighted = action.payload;
    },   
    setTransportRequests: (state, action) => {
      state.transportRequests = action.payload;
    }, 
    setTransportChurch: (state, action) => {
      state.transportChurch = action.payload;
    }, 
    setHotelImages: (state, action) => {
      state.hotelImages = action.payload;
    }, 
    setWeedingPassList: (state, action) => {
      state.weedingPassList = action.payload;
    }, 
    setFullWeedingPassList: (state, action) => {
      state.fullWeedingPassList = action.payload;
    }, 
    setExternalGuestHighlighted: (state, action) => {
      state.externalGuestHighlighted = action.payload;
    }, 
    setSpecialRequests: (state, action) => {
      state.specialRequests = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setInfo, setMasterSale, setSeatingPlan, setGuestList, setFullGuestList, setGuestHighlighted,
  setCourtshipLine, setTransportRequests, setTransportChurch, setHotelImages, setWeedingPassList,
  setFullWeedingPassList, setExternalGuestHighlighted, setSpecialRequests } = groupSlice.actions;