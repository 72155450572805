import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../../helpers/numericFields';
import { Card, Col, Row } from 'react-bootstrap';


export const PaymentsTable = () => {
  const { customerPayments } = useSelector((state) => state.individualFiles);

  const columns = [
    {
      name: "#",
      width: "60px",
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.id}</span>
    },
    {
      name: "Expediente",
      width: "85px",
      cell: row => <span className="text-center w-100">
        <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
          <Link to={`/panel/expedienteIndividual?id=${row.idIndividualFile}`}
            className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{row.idIndividualFile}</span></Link>
        </OverlayTrigger>
      </span>
    },
    {
      name: "Fecha",
      selector: row => [row.date],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.date}</span>
    },
    {
      name: "Cliente",
      selector: row => [row.customer],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.customer}</span>
    },
    {
      name: "Monto",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{currencyFormat(row.amount)}</span>
    },
    {
      name: "Factura",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.invoice ? "Si" : "No"}</span>
    },
    {
      name: "Metodo",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.paymentMethod}</span>
    },
    {
      name: "Forma de pago",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.paymentWay}</span>
    },
  ]
  const tableDatas = {
    columns,
    data: customerPayments.payments,
  };
  return (
    <Row>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h3 className="card-title mb-0">Pagos</h3>
          </Card.Header>
          <Card.Body>
            <div className="dataTable">
              <div className="table-responsive">
                <DataTableExtensions {...tableDatas} >
                  <DataTable
                    columns={columns}
                    data={customerPayments.payments}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    highlightOnHover />
                </DataTableExtensions>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}