import { useState } from "react";
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import styles from './SpecialRequestDetails.module.css'
import { Link } from "react-router-dom";
import { EditSpecialRequest } from "./EditSpecialRequest";

export const SpecialRequestDetails = ({ pendingSpecialRequestGroupFile, pendingSpecialRequestIndividualFile, pendingSpecialRequestFile }) => {
  const [editSpecialRequest2, setEditSpecialRequest2] = useState(false);
  const [requestSelected, setEditRequestSelected] = useState({});
  let row=1;
  const edit2 = (request) => {
    setEditSpecialRequest2(true);
    setEditRequestSelected(request)
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Exp</th>              
              <th>Solicitud</th>
              <th>Respuesta</th>
              <th>Estado</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              pendingSpecialRequestGroupFile.map((request) => (
                <tr key={request.id}>
                  <td>{row++}</td>                  
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expedienteGrupo?id=${request.idGroupFile}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{request.idGroupFile}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{request.request}</td>
                  <td>{request.answer}</td>
                  <td>{request.statusRequest}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(request)}></i>
                  </td>
                </tr>))
            }
            {
              pendingSpecialRequestIndividualFile.map((request) => (
                <tr key={request.id}>
                  <td>{row++}</td>                  
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expedienteIndividual?id=${request.idIndividualFile}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{request.idIndividualFile}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{request.request}</td>
                  <td>{request.answer}</td>                  
                  <td>{request.statusRequest}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(request)}></i>
                  </td>
                </tr>))
            }
            {
              pendingSpecialRequestFile.map((request) => (
                <tr key={request.id}>
                  <td>{row++}</td>                  
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expediente?id=${request.idFile}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{request.idFile}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{request.request}</td>
                  <td>{request.answer}</td>                  
                  <td>{request.statusRequest}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(request)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <EditSpecialRequest request={requestSelected}
        isOpen={editSpecialRequest2} setIsOpen={setEditSpecialRequest2} />
    </div>
  )
}
