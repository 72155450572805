import { useDispatch, useSelector } from "react-redux";
import { downloadContract } from "../../../store/slices/group/thunks";

export const ContractScreen = () => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.group);  

  return (
    <div className="mt-5">
      {
        info.contract 
        ? <button className="btn btn-light btn-info mt-2" 
          onClick={() => dispatch(downloadContract(info.contract, info.code))}>Descargar Contrato</button>
        : <div style={{fontSize: 16}}>Tu contrato aun no esta disponible</div>
      } 
    </div>
  )
}
