import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";
import { editAirportHotel } from "../../../store/slices/groupFiles/thunks";
import { editAirportHotel as editAirportHotel2 } from "../../../store/slices/individualFiles/thunks";

export const EditAirportHotel = ({ request, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  let statusRequest =[{ value: "En proceso", label: "En proceso" }, 
  { value: "Confirmada", label: "Confirmada" },
  { value: "Cancelada", label: "Cancelada" }];

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Solicitud De Transporte
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            notes: request.notes || "",
            statusRequest: { "value": request.statusRequest, "label": request.statusRequest },
          }}
          onSubmit={(({ statusRequest, notes }, { resetForm, setErrors }) => {

            if (!statusRequest) {
              return setErrors({ "statusRequest": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            if(request.idIndividualFile){
              dispatch(editAirportHotel2(request.id, notes, statusRequest.value, success));
            }else{
              dispatch(editAirportHotel(request.id, notes, statusRequest.value, success));
            }            
          })}
          validationSchema={
            Yup.object({

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4}
                          onChange={(e) => {
                            setFieldValue("notes", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Estado de la solicitud:*' name="statusRequest" options={statusRequest} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("statusRequest", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Row style={{ width: "100%" }}>
                    <div className="col-lg-12 col-md-5 col-5" style={{ textAlign: "right" }}>
                      <Button variant="primary" type="submit">
                        Guardar Informacion
                      </Button>
                    </div>
                  </Row>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  )
}
