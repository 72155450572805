import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux';
import { resetRommingList } from '../../../store/slices/groupFiles/groupFilesSlice';
import { roomingList } from '../../../store/slices/groupFiles/thunks';
import { Control } from './Control';
import { RoomingTable } from './RoomingTable';
import { Summary } from './Summary';

export const Rooming = ({ groupFile }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupFile.id) {
      dispatch(roomingList(groupFile.id));
    }

    return () => {
      dispatch(resetRommingList());
    }
  }, [groupFile.id])

  return (
    <div>
      <RoomingTable />      
      <Control />
      <Summary />

    </div>
  )
}
