import { Table } from "react-bootstrap";

export const SpecialRequestDetails = ({ specialRequests }) => {

  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Solicitud</th>
                <th>Respuesta</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                specialRequests.filter(x => x.statusRequest != 'Cancelada' && x.statusRequest != 'Rechazada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.request}</td>
                    <td>{request.answer}</td>
                    <td>{request.statusRequest}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>        
      </div>
      <div style={{ fontSize: 22 }}>Solicitudes Rechazadas</div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Solicitud</th>
                <th>Respuesta</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                specialRequests.filter(x => x.statusRequest == 'Cancelada' || x.statusRequest == 'Rechazada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.request}</td>
                    <td>{request.answer}</td>
                    <td>{request.statusRequest}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>        
      </div>
    </div>
  )
}
