import { createSlice } from '@reduxjs/toolkit';

export const tutorialsSlice = createSlice({
  name: 'Tutorials',
  initialState: {
    paymentsTutorial: [],  
  },
  reducers: {
    setPaymentsTutorial: (state, action) => {
      state.paymentsTutorial = action.payload;
    },
  }
});


// Action creators are generated for each case reducer function
export const { setPaymentsTutorial } = tutorialsSlice.actions;