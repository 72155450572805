import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../helpers/numericFields";
import { useDispatch } from 'react-redux';
import { addCabinCruise } from "../../store/slices/quotes/thunks";
import { MyTextInput66 } from "../ui/controls/MyTextInput66";

export const AddCabin = ({ cruise, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Cabina A Crucero
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            cabin: '',
            rate: '',
            netPrice: '',
            tax: '',
            tip: '',
          }}
          onSubmit={(({ cabin, rate, netPrice, tax, tip }, { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addCabinCruise(cruise.id, cabin, rate, netPrice, tax, tip, cruise.idQuote, success));
          })}
          validationSchema={
            Yup.object({
              cabin: Yup.string()
                .required("Requerido"),
              rate: Yup.string()
                .required("Requerido"),
              netPrice: Yup.string()
                .required("Requerido"),
              tax: Yup.string()
                .required("Requerido"),
              tip: Yup.string()
                .required("Requerido"),

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Cabina:*' name='cabin' mode='horizontal' rows={2} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Tarifa Publica:*' name='rate' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("rate", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Tarifa Neta:*' name='netPrice' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("netPrice", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Impuestos:*' name='tax' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("tax", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Propinas:*' name='tip' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("tip", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <label className='col-form-label col-12' >Moneda: {cruise.currency}</label>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
