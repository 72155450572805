import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../../helpers/numericFields";
import { ModalImage } from "../../../ui/controls/ModalImage";
import styles from './AdvancePaymentDetails.module.css'

export const RejectedPaymentDetails = ({ masterSale }) => {
  const [viewImage, setViewImage] = useState(false);
  const [image, setImage] = useState("");

  const viewImage2 = (payment) => {
    setViewImage(true);
    setImage(payment.image)
  }


  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Agente</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>
              <th>Referencia</th>
              <th>Evidencia</th>
              <th>Monto</th>
              <th>Notas</th>              
            </tr>
          </thead>
          <tbody>
            {
              masterSale.rejectedPayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.agent}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>
                  <td>{payment.reference}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.image && 
                        <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(payment)}></i>
                    }
                  </td>
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                </tr>))
            }            
          </tbody>
        </Table>
      </div>
      <ModalImage image={image} isOpen={viewImage} setIsOpen={setViewImage} />
    </div>
  )
}
