import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addGuestChurch, deleteGuestChurch } from "../../../store/slices/group/thunks";

export const ChurchDetails = ({ transportChurch, code }) => {
  const dispatch = useDispatch();

  const addGuest = (idGuest) => {        
    dispatch(addGuestChurch(code, idGuest)); 
  }

  const deleteGuest = (idGuest) => {        
    dispatch(deleteGuestChurch(code, idGuest)); 
  }

  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div style={{ fontSize: 22, textAlign:"center" }}>Iglesia</div>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Incluido</th>
                <th></th>                
                <th></th>                
              </tr>
            </thead>
            <tbody>
              {
                transportChurch.map((guest, index) => (
                  <tr key={guest.idGuestRoom}>
                    <td>{index + 1}</td>
                    <td>{guest.guest}</td>
                    <td>{guest.id ? "Si" : ""}</td>
                    <td>{guest.airline}</td>
                    <td style={{textAlign:"center"}}>
                      {guest.id
                        ? <button className="btn btn-danger" onClick={() => deleteGuest(guest.idGuestRoom)}>Remover</button>
                        : <button className="btn btn-primary" onClick={() => addGuest(guest.idGuestRoom)}>Incluir</button>
                      }
                      </td>
                  </tr>))
              }
            </tbody>
          </Table>          
          
        </div>        
      </div>                  
    </div>
  )
}
