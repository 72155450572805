import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { editContactWay, editLodgingType } from "../../../store/slices/catalogue/thunks";
import { useDispatch } from "react-redux";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";

export const EditLodgingType = ({ item, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Tipo De Hospedaje
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: item.name,
            status: item.status === 1 ? true : false,
          }}
          onSubmit={(({ name, status }, { resetForm, setErrors }) => {
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editLodgingType(item.id, name, status, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Estatus:" name="status" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
