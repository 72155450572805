import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../../helpers/numericFields";
import styles from './GroupSalesDetails.module.css'

export const GroupSalesDetails = ({ masterSale }) => {

  const edit = () => {
    
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Mayorista</th>
              <th>Concepto</th>
              <th>Descripcion</th>
              <th>Tarifa Publica</th>
              <th>Tarifa Neta</th>
              <th>Comision</th>
            </tr>
          </thead>
          <tbody>
            {
              masterSale.groupSales.map((room, index) => (
                <tr key={room.id}>
                  <td>{room.wholesaler}</td>
                  <td>Hospedaje</td>
                  <td>{room.description}</td>
                  <td>{currencyFormat(room.publicPrice)}</td>
                  <td>{currencyFormat(room.netPrice)}</td>
                  <td>{currencyFormat(room.fee)}</td>
                </tr>))
            }            
          </tbody>
        </Table>
      </div>
    </div>
  )
}
