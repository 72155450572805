import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AddGroupFile } from './AddGroupFile';
import { getAdditionalList } from '../../store/slices/groupFiles/thunks';
import { SearchGroupFileTable } from './SearchGroupFileTable';
import { getCustomersList, getCategoriesList } from '../../store/slices/general/thunks';
import { FormSearchFile } from './FormSearchFile';
import { setGroupFilesList } from '../../store/slices/groupFiles/groupFilesSlice';

export const SearchGroupFileScreen = () => {
  const dispatch = useDispatch();
  const [addGroupFile, setAddGroupFile] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesList());
  }, [])

  useEffect(() => {
    dispatch(getAdditionalList());
  }, [])

  useEffect(() => {
    dispatch(getCustomersList())
  }, [])

  useEffect(() => {  
    return () => {
      dispatch(setGroupFilesList([]));
    }
  }, [])
  

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" onClick={() => setAddGroupFile(true)}>Agregar Grupo</button>
        <AddGroupFile isOpen={addGroupFile} setIsOpen={setAddGroupFile} />
      </div>

      <FormSearchFile />
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Expedientes</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <SearchGroupFileTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
