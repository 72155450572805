import React from 'react'
import { AdvancePayment } from './AdvancePayment'
import { MasterSale } from './MasterSale'
import { RejectedPayment } from './RejectedPayment'
import { useDispatch } from 'react-redux'
import { bookingCoupon } from '../../store/slices/guest/thunks'

export const Details = ({info}) => {
  const dispatch = useDispatch();
  return (
    <div key={info.id} style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div style={{ fontSize: 22 }}>Expediente #{info.id} - {info.customer}</div>      
      {        
        info.masterSale.advancePayments.filter(x=>x.approved == 1).reduce((a, b) => a += b.amount, 0) >=
        info.masterSale.groupRooms.reduce((a, b) => a += (b.publicPriceTotal), 0)
        && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {
                info.masterSale.groupRooms.map(room => (
                  <button key={room.id} className={`btn text-white btn-secondary`}
                    onClick={() => dispatch(bookingCoupon(room.id, info.code))} >Descargar Cupon Hospedaje</button>
                ))
            }
          </div>       
      }
      <AdvancePayment masterSale={info.masterSale} code={info.code} paydayLimit={info.paydayLimit} paydayLimitDays={info.paydayLimitDays} />
      <RejectedPayment masterSale={info.masterSale} />
      <MasterSale masterSale={info.masterSale} />
    </div>
  )
}
