import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsTutorialDetails } from './PaymentsTutorialDetails';
import { getPaymentsTutorial } from '../../../store/slices/tutorials/thunks';
import { AddPaymentsTutorialImage } from './AddPaymentsTutorialImage';

export const PaymentsTutorial = () => {
  const dispatch = useDispatch();
  const { paymentsTutorial } = useSelector((state) => state.tutorials);
  const [addImage, setAddImage] = useState(false);

  useEffect(() => {
    dispatch(getPaymentsTutorial());
  }, [])

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Tutorial para realizar pagos</div>  

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" onClick={() => setAddImage(true)}>Agregar Imagen</button>
        <AddPaymentsTutorialImage isOpen={addImage} setIsOpen={setAddImage} />    
      </div>


      <PaymentsTutorialDetails paymentsTutorial={paymentsTutorial} />
    </div>
  )
}
