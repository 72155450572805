import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import { setAirlines, setCalendarEvents, setContactWay, setCustomers, setHotelImages, setHotels, setLodgingType, setReminderFilter, setReminderList, setReminderListToday, setRolesList, setServices, setUsersList, setWholesalers } from "./catalogueSlice";
import { getCustomersList as getCustomersListGeneral, getAirlinesList as getAirlinesListGeneral,
  getHotelsList as getHotelsListGeneral, getServicesList as getServicesListGeneral,
  getWholesalersList as getWholesalersListGeneral, getContactWayList as getContactWayListGeneral,
  getLodgingTypeList as getLodgingTypeListGeneral
 } from "../general/thunks";

export const getCustomersList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setCustomers([]));
      const resp = await viajesApi.get("/catalogue/customersList");
      dispatch(setCustomers(resp.data.results));
    } catch (error) {

    }
  }
}

export const addCustomer = (idContactWay, name, lastName, mothersLastName, cellphone, email, telephone, 
  whoRecommend, contact, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addCustomer", {
        idContactWay, name, lastName, mothersLastName, cellphone, email, telephone, whoRecommend, contact
      });
      success();
      dispatch(getCustomersList());
      dispatch(getCustomersListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editCustomer = (id, idContactWay, name, lastName, mothersLastName, cellphone, email, 
  telephone, whoRecommend, contact, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editCustomer", {
        id, idContactWay, name, lastName, mothersLastName, cellphone, email, telephone, whoRecommend, 
        contact, status
      });
      success();
      dispatch(getCustomersList());
      dispatch(getCustomersListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getAirlinesList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAirlines([]));
      const resp = await viajesApi.get("/catalogue/airlinesList");
      dispatch(setAirlines(resp.data.results));
    } catch (error) {

    }
  }
}

export const addAirline = (name, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addAirline", {
        name
      });
      success();
      dispatch(getAirlinesList());
      dispatch(getAirlinesListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editAirline = (id, name, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editAirline", {
        id, name,  status
      });
      success();
      dispatch(getAirlinesList());
      dispatch(getHotelsList());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getHotelsList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setHotels([]));
      const resp = await viajesApi.get("/catalogue/hotelsList");
      dispatch(setHotels(resp.data.results));
    } catch (error) {

    }
  }
}

export const addHotel = (name, code, address, city, state, country, telephone, email, notes, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addHotel", {
        name, code, address, city, state, country, telephone, email, notes
      });
      success();
      dispatch(getHotelsList());
      dispatch(getHotelsListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editHotel = (id, name, code, address, city, state, country, telephone, email, notes, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editHotel", {
        id, name, code, address, city, state, country, telephone, email, notes, status
      });
      success();
      dispatch(getHotelsList());
      dispatch(getHotelsListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const addHotelImage = (idHotel, image, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idHotel", idHotel);
      if (image)
        form.append("image", image, image.Name);      

      const resp = await viajesApi.post("/catalogue/addHotelImage", form);
      success();
      dispatch(getHotelImagesList(idHotel));
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getHotelImagesList = (idHotel) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setHotelImages([]));
      const resp = await viajesApi.get("/catalogue/hotelImages",{
        params:{ idHotel }
      });
      dispatch(setHotelImages(resp.data.results));
    } catch (error) {

    }
  }
}

export const deleteHotelImage = (image, idHotel) => {
  return async (dispatch, getState) => {
    try {      
      await viajesApi.delete("/catalogue/deleteHotelImage",{
        params:{ image }
      });
      dispatch(getHotelImagesList(idHotel));
    } catch (error) {

    }
  }
}

export const getServicesList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setServices([]));
      const resp = await viajesApi.get("/catalogue/servicesList");
      dispatch(setServices(resp.data.results));
    } catch (error) {

    }
  }
}

export const addService = (name, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addService", {
        name
      });
      success();
      dispatch(getServicesList());
      dispatch(getServicesListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editService = (id, name, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editService", {
        id, name,  status
      });
      success();
      dispatch(getServicesList());
      dispatch(getServicesListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getWholesalersList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWholesalers([]));
      const resp = await viajesApi.get("/catalogue/wholesalerList");
      dispatch(setWholesalers(resp.data.results));
    } catch (error) {

    }
  }
}

export const addWholesaler = (name, code, address, telephone, email, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addWholesaler", {
        name, code, address, telephone, email
      });
      success();
      dispatch(getWholesalersList());
      dispatch(getWholesalersListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editWholesaler = (id, name, code, address, telephone, email, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editWholesaler", {
        id, name, code, address, telephone, email, status
      });
      success();
      dispatch(getWholesalersList());
      dispatch(getWholesalersListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getContactWayList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setContactWay([]));
      const resp = await viajesApi.get("/catalogue/contactWayList");
      dispatch(setContactWay(resp.data.results));
    } catch (error) {

    }
  }
}

export const addContactWay = (name, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addContactWay", {
        name
      });
      success();
      dispatch(getContactWayList());
      dispatch(getContactWayListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editContactWay = (id, name, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editContactWay", {
        id, name,  status
      });
      success();
      dispatch(getContactWayList());
      dispatch(getContactWayListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getLodgingTypeList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLodgingType([]));
      const resp = await viajesApi.get("/catalogue/lodgingTypeList");
      dispatch(setLodgingType(resp.data.results));
    } catch (error) {

    }
  }
}

export const addLodgingType = (name, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addLodgingType", {
        name
      });
      success();
      dispatch(getLodgingTypeList());
      dispatch(getLodgingTypeListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editLodgingType = (id, name, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editLodgingType", {
        id, name,  status
      });
      success();
      dispatch(getLodgingTypeList());
      dispatch(getLodgingTypeListGeneral());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getRolesList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setRolesList([]));
      const resp = await viajesApi.get("/catalogue/rolesList");
      dispatch(setRolesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {
      dispatch(setRolesList([]));
    }
  }
}

export const getUsersList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setUsersList([]));
      const resp = await viajesApi.get("/catalogue/usersList");
      dispatch(setUsersList(resp.data.results));
    } catch (error) {
      dispatch(setUsersList([]));
    }
  }
}

export const addUser = (name, userName, password, idRole, image, fee, success) => {
  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("name", name);
      form.append("userName", userName);
      form.append("password", password);
      form.append("idRole", idRole);      
      if (image)
        form.append("image", image, image.Name);
      form.append("fee", fee);
      const resp = await viajesApi.post("/catalogue/addUser", form);
      success();
      dispatch(getUsersList());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const editUser = (id, name, userName, password, idRole, image, fee, status, success) => {
  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("name", name);
      form.append("userName", userName);
      form.append("password", password);
      form.append("idRole", idRole);
      if (image)
        form.append("image", image, image.Name);
      form.append("fee", fee);
      form.append("status", status);

      const resp = await viajesApi.put("/catalogue/editUser", form);
      success();
      dispatch(getUsersList());
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getReminderListToday = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/catalogue/reminderListToday");
      dispatch(setReminderListToday(resp.data.results));
    } catch (error) {

    }
  }
}

export const getReminderList = (user, date) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/catalogue/reminderList", {
      params: {
        user, date
      }});
      dispatch(setReminderList(resp.data.results));
      dispatch(setReminderFilter({user, date}));
    } catch (error) {

    }
  }
}

export const addReminder = (title, description, date, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addReminder", {
        title, description, date
      });
      const filter = getState().catalogue.reminderFilter
      success();
      dispatch(getReminderListToday());
      dispatch(getReminderList(filter.user, filter.date));
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const getCalendarEvents = () => {
  return async (dispatch, getState) => {

    dispatch(setCalendarEvents({ isLoading: true, events: [] }));
    try {
      const resp = await viajesApi.get("/catalogue/calendarEvents");
      dispatch(setCalendarEvents({ isLoading: false, events: resp.data.results }));
    } catch (error) {
      dispatch(setCalendarEvents({ isLoading: false, events: [] }));
    }
  }
}

export const getBlankSpacesInCalendar = (startDate, endDate, days, success) => {
  return async (dispatch, getState) => {

    dispatch(setCalendarEvents({ isLoading: true, events: [] }));
    try {
      const resp = await viajesApi.get("/catalogue/getBlankSpacesInCalendar", {
        params: {
          startDate, endDate, days
        }
      });   
      success(); 
      dispatch(setCalendarEvents({ isLoading: false, events: resp.data.results }));
    } catch (error) {
      dispatch(setCalendarEvents({ isLoading: false, events: [] }));
    }
  }
}

export const editReminder = (id, title, description, date, status, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editReminder", {
        id, title, description, date,  status
      });
      const filter = getState().catalogue.reminderFilter
      success();
      dispatch(getReminderListToday());
      dispatch(getReminderList(filter.user, filter.date));
      Swal.fire("Catalogos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Catalogos", error.response.data.msg, "error");
    }
  }
}

export const addEvent = (subject, concept, time, names, topic, address, contact, operator, 
  date, notes, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/catalogue/addEvent", {
        subject, concept, time, names, topic, address, contact, operator, date, notes
      });
      success();
      dispatch(getCalendarEvents());
      Swal.fire("Calendario", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Calendario", error.response.data.msg, "error");
    }
  }
}

export const editEvent = (id, subject, concept, time, names, topic, address, contact, operator, 
  date, notes, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/catalogue/editEvent", {
        id, subject, concept, time, names, topic, address, contact, operator, date, notes
      });
      success();
      dispatch(getCalendarEvents());
      Swal.fire("Calendario", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Calendario", error.response.data.msg, "error");
    }
  }
}

export const deleteEvent = (id, success) => {
  return async (dispatch, getState) => {
    try {      
      const resp = await viajesApi.delete("/catalogue/deleteEvent",{
        params:{ id }
      });
      success();
      dispatch(getCalendarEvents());
      Swal.fire("Calendario", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Calendario", error.response.data.msg, "success");
    }
  }
}