import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { editReminder } from "../../../store/slices/catalogue/thunks";
import { useDispatch } from "react-redux";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";

export const EditReminder = ({ item, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Recordatorio
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            title: item.title,
            description: item.description,
            date: item.date,
            status: item.status === 1 ? true : false,
          }}
          onSubmit={(({ title, description, date, status }, { resetForm, setErrors }) => {
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            let date2;
            if (date) {
              if (date.year) {
                date2 = date.year + "-" + (date.monthIndex + 1) + "-" + date.day
              } else {
                const temp = date.split("-")
                date2 = temp[2] + "-" + temp[1] + "-" + temp[0]
              }
            }

            dispatch(editReminder(item.id, title, description, date2, status, success));

          })}
          validationSchema={
            Yup.object({
              title: Yup.string()
                .required("Requerido"),
              description: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Titulo:*' name='title' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Descripcion:' name='description' mode='horizontal' rows={2} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha:' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Estatus:" name="status" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
