import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from './WaitingListDetails.module.css'
import { EditRoom } from "./EditRoom";

export const WaitingListDetails = ({ groupFile }) => {
  const { waitingList } = useSelector((state) => state.groupFiles);
  const [editRoom, setEditRoom] = useState(false);
  const [roomSelected, setRoomSelected] = useState({});


  const edit2 = (room) => {
    setEditRoom(true);
    setRoomSelected(room)
  }


  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Tipo de habitacion</th>
              <th>Cantidad</th>
              <th>Fecha de entrada</th>
              <th>Fecha de salida</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              waitingList.map((room, index) => (
                <tr key={room.id}>
                  <td>{index + 1}</td>
                  <td>{room.name}</td>
                  <td>{room.room}</td>
                  <td>{room.quantity}</td>
                  <td>{room.checkInDate}</td>
                  <td>{room.checkOutDate}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(room)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>

      <EditRoom room={roomSelected} groupFile={groupFile}
        isOpen={editRoom} setIsOpen={setEditRoom} />
    </div>
  )
}
