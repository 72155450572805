import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { onlyIntegers } from "../../../helpers/numericFields";
import { useDispatch } from 'react-redux';
import { deleteWaitingList, editWaitingList } from "../../../store/slices/groupFiles/thunks";

export const EditRoom = ({ room: roomWaitingList, groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deleteWaitingList(roomWaitingList.id, groupFile.id, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Habitacion En Lista De Espera
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: roomWaitingList.name,
            room: roomWaitingList.room,
            quantity: roomWaitingList.quantity,
            checkInCheckOutDate: [roomWaitingList.checkInDate, roomWaitingList.checkOutDate],
          }}
          onSubmit={(({ name, quantity, room, checkInCheckOutDate
          }, { resetForm, setErrors }) => {

            let checkInDate;
            if (checkInCheckOutDate[0].year) {
              checkInDate = checkInCheckOutDate[0].year + "-" + (checkInCheckOutDate[0].monthIndex + 1) + "-" + checkInCheckOutDate[0].day
            } else {
              const temp = checkInCheckOutDate[0].split("-")
              checkInDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let checkOutDate;
            if (checkInCheckOutDate[1].year) {
              checkOutDate = checkInCheckOutDate[1].year + "-" + (checkInCheckOutDate[1].monthIndex + 1) + "-" + checkInCheckOutDate[1].day
            } else {
              const temp = checkInCheckOutDate[1].split("-")
              checkOutDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!checkInCheckOutDate || checkInCheckOutDate.length !== 2) {
              return setErrors({ "checkInCheckOutDate": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }
            
            dispatch(editWaitingList(roomWaitingList.id, name, room, quantity,  checkInDate, checkOutDate,
               groupFile.id, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              room: Yup.string()
                .required("Requerido"),
              quantity: Yup.string()
                .required("Requerido"),

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Tipo de habitacion:*' name='room' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Cantidad:' name='quantity' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("quantity", parseInt(e.target.value || 0));
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Entrada y Salida:*' name="checkInCheckOutDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("checkInCheckOutDate", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
