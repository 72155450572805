import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { onlyIntegers, onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { deleteRoomLodgingGroup, editRoomLodgingGroup } from "../../../store/slices/groupFiles/thunks";
import { MyTextInput66 } from "../../ui/controls/MyTextInput66";
import { MyTextLabel66 } from "../../ui/controls/MyTextLabel66";

export const EditRoom = ({ room, groupFile, lodging, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { lodgingGroupList } = useSelector((state) => state.groupFiles);

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deleteRoomLodgingGroup(room.id, groupFile.id, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Habitacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            lodging: { value: lodging.id, label: lodging.name },
            room: room.room,
            costPerNight: room.costPerNight,
            feeService: room.feeService,
            tax: room.tax,
            others: room.others,
            fee: room.fee,
          }}
          onSubmit={(({ lodging, room: room2,
            costPerNight, feeService, tax, others, fee }, { resetForm, setErrors }) => {

            if (!lodging) {
              return setErrors({ "lodging": "Requerido" })
            }
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editRoomLodgingGroup(room.id, lodging.value, room2,
              costPerNight, feeService, tax, others, fee, groupFile.id, success));
          })}
          validationSchema={
            Yup.object({
              room: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hospedaje:*' name="lodging" options={
                          lodgingGroupList.map(item => ({ "value": item.id, "label": item.hotel }))
                        } mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("lodging", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Habitacion:*' name='room' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label="Adultos:" value={room.adults} mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label="Juniors:" value={room.juniors} mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label="Menores:" value={room.minors} mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label="Infantes:" value={room.infants} mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo x noche:' name='costPerNight' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("costPerNight", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Cuota servicio:' name='feeService' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("feeService", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Impuestos (%):' name='tax' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("tax", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Otros:' name='others' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("others", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Comision:' name='fee' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("fee", e.target.value);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
