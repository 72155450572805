import { Table } from "react-bootstrap";

export const AirportDetails = ({ transportRequests }) => {

  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Vuelo</th>
                <th>Aerolinea</th>
                <th>Fecha de llegada vuelo</th>
                <th>Personas</th>
                <th>Nombres</th>
                <th>Terminal</th>
                <th>Modo</th>
                <th>Estado</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody>
              {
                transportRequests.filter(x=>x.type== "Aeropuerto-Hotel" && x.statusRequest != 'Cancelada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.type}</td>
                    <td>{request.flight}</td>
                    <td>{request.airline}</td>
                    <td>{request.date}</td>
                    <td>{request.people}</td>
                    <td>{request.names}</td>
                    <td>{request.terminal}</td>
                    <td>{request.mode}</td>
                    <td>{request.statusRequest}</td>
                    <td>{request.notes}</td>
                  </tr>))
              }
            </tbody>
          </Table>
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Vuelo</th>
                <th>Aerolinea</th>
                <th>Fecha de salida vuelo</th>
                <th>Personas</th>
                <th>Nombres</th>
                <th>Terminal</th>
                <th>Modo</th>
                <th>Estado</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody>
              {
                transportRequests.filter(x=>x.type== "Hotel-Aeropuerto" && x.statusRequest != 'Cancelada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.type}</td>
                    <td>{request.flight}</td>
                    <td>{request.airline}</td>
                    <td>{request.date}</td>
                    <td>{request.people}</td>
                    <td>{request.names}</td>
                    <td>{request.terminal}</td>
                    <td>{request.mode}</td>
                    <td>{request.statusRequest}</td>
                    <td>{request.notes}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>        
      </div>
      <div style={{ fontSize: 22 }}>Solicitudes Rechazadas</div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Vuelo</th>
                <th>Aerolinea</th>
                <th>Fecha de llegada vuelo</th>
                <th>Personas</th>
                <th>Nombres</th>
                <th>Terminal</th>
                <th>Modo</th>
                <th>Estado</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody>
              {
                transportRequests.filter(x=>x.type== "Aeropuerto-Hotel" && x.statusRequest== 'Cancelada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.type}</td>
                    <td>{request.flight}</td>
                    <td>{request.airline}</td>
                    <td>{request.date}</td>
                    <td>{request.people}</td>
                    <td>{request.names}</td>
                    <td>{request.terminal}</td>
                    <td>{request.mode}</td>
                    <td>{request.statusRequest}</td>
                    <td>{request.notes}</td>
                  </tr>))
              }
            </tbody>
          </Table>
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Vuelo</th>
                <th>Aerolinea</th>
                <th>Fecha de salida vuelo</th>
                <th>Personas</th>
                <th>Nombres</th>
                <th>Terminal</th>
                <th>Modo</th>
                <th>Estado</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody>
              {
                transportRequests.filter(x=>x.type== "Hotel-Aeropuerto" && x.statusRequest== 'Cancelada').map((request, index) => (
                  <tr key={request.id}>
                    <td>{index + 1}</td>
                    <td>{request.type}</td>
                    <td>{request.flight}</td>
                    <td>{request.airline}</td>
                    <td>{request.date}</td>
                    <td>{request.people}</td>
                    <td>{request.names}</td>
                    <td>{request.terminal}</td>
                    <td>{request.mode}</td>
                    <td>{request.statusRequest}</td>
                    <td>{request.notes}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>        
      </div>
    </div>
  )
}
