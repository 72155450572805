import React, { useEffect, useState } from 'react'
import styles from './Contact.module.css'
import { useNavigate } from 'react-router-dom';
import location from '../../assets/images/location-pointer.png'
import telephone from '../../assets/images/telephone.png'

export const Contact = () => {
  const navigate = useNavigate();

  return (    
      <div className={styles.form}>
        <label className={`${styles.name} form-label text-center`}>VIAJEMOS CON SOL</label>
        <div className={`${styles.telephone}`}><img src={telephone} width={15} height={20} /><label className={`${styles.info} `}>Teléfono: +52 614-362-3709</label></div>
        <div className={`${styles.schedule}`}><div style={{ width: 17}} ></div><label className={`${styles.info}`}>Horario: Lunes - Viernes de 10 AM - 2 PM y 3 PM - 6 PM. Sabados 10 AM - 2 PM</label></div>
        <div className={`${styles.location}`}><img src={location} width={18} height={20} /><label className={`${styles.info} `}>Dirección: C. 35 1614, Obrera, 31350 Chihuahua, Chih.</label></div>
      </div>          
  )
}
