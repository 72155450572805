import React from 'react'
import { Airport } from './Airport';

export const Details = ({info}) => {

  return (
    <div key={info.id} style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div style={{ fontSize: 22 }}>Transporte</div>      
      <Airport code={info.code} />
    </div>
  )
}
