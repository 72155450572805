import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './AccountsPayableDetails.module.css'
import { AddPaymentWholesaler } from "./AddPaymentWholesaler";

export const AccountsPayableDetails = ({ masterSaleGroup }) => {
  const { role } = useSelector((state) => state.auth);
  const [addPay, setAddPay] = useState(false)
  const [account, setAccount] = useState({})

  const pay2 = (account) => {
    setAccount(account);
    setAddPay(true);
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Mayorista</th>
              <th>Concepto</th>
              <th>Descripcion</th>
              <th>Tarifa Publica</th>
              <th>Tarifa Neta</th>
              <th>Comision</th>
              <th>Pagado</th>
              <th>Saldo</th> 
              {
                role === "DIRECTOR"
                  &&  <th>Pagar</th>
              }                           
            </tr>
          </thead>
          <tbody>
            {
              masterSaleGroup.accountsPayable.map((account, index) => (
                <tr key={`${account.id}-${account.concept}`}>
                  <td>{account.wholesaler}</td>
                  <td>{account.concept}</td>
                  <td>{account.description}</td>
                  <td>{currencyFormat(account.publicPriceTotal)}</td>
                  <td>{currencyFormat(account.netPriceTotal)}</td>
                  <td>{currencyFormat(account.fee)}</td>       
                  <td>{currencyFormat(account.paid)}</td>                          
                  <td style={{ textAlign: "center" }}>{currencyFormat(account.paid - account.netPriceTotal)}</td>    
                  {
                    role === "DIRECTOR"
                      &&   <td style={{ textAlign: "center" }}>
                      <i className={`fa fa-dollar ${styles.pay}`} onClick={() => pay2(account)}></i>
                    </td>   
                  }                                
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <AddPaymentWholesaler masterSaleGroup={masterSaleGroup} account={account} isOpen={addPay} setIsOpen={setAddPay}  />
    </div>
  )
}
