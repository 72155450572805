import { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../../ui/controls/MySelect";
import { MyTextInput } from "../../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../../ui/controls/MyDatePicker";
import { onlyIntegers } from "../../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { MyTextLabel } from "../../../ui/controls/MyTextLabel";
import { MyTextLabel84 } from "../../../ui/controls/MyTextLabel84";
import Swal from "sweetalert2";
import { getRoomlodgingGroupList } from "../../../../store/slices/groupFiles/thunks";
import { addIndividualRoom, editIndividualRoom } from "../../../../store/slices/individualFiles/thunks";
import { setRoomLodgingGroupList } from "../../../../store/slices/groupFiles/groupFilesSlice";

export const AddRoom = ({ individualFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { roomLodgingGroupList } = useSelector((state) => state.groupFiles);
  const [rooms, setRooms] = useState([])
  const [roomSelected, setRoomSelected] = useState({})
  const [daysNights, setDaysNights] = useState({ days: 0, nights: 0 });

  useEffect(() => {
    const items = [];
    for (const lodging of roomLodgingGroupList) {
      for (const room of lodging.rooms) {
        items.push({
          "value": room.id, "label": lodging.name + " - " + room.room,
          "publicPrice": room.publicPrice,
          "daysBefore": lodging.daysBefore, "daysAfter": lodging.daysAfter
        })
      }
    }
    setRooms(items)
  }, [roomLodgingGroupList])

  useEffect(() => {
    setDaysNights({ days: individualFile.days, nights: individualFile.nights })
  }, [isOpen])

  useEffect(() => {    
    if (individualFile.idGroup) {
      dispatch(getRoomlodgingGroupList(individualFile.idGroup))
    }
    return () => {
      dispatch(setRoomLodgingGroupList([]));
    }
  }, [])

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Partida De Hospedaje
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            room: undefined,
            quantity: 1,
            checkInCheckOutDate: [individualFile.startDate, individualFile.endDate]
          }}
          onSubmit={(({ room: room2, quantity, checkInCheckOutDate }, { resetForm, setErrors }) => {

            let checkInDate;
            if (checkInCheckOutDate[0].year) {
              checkInDate = checkInCheckOutDate[0].year + "-" + (checkInCheckOutDate[0].monthIndex + 1) + "-" + checkInCheckOutDate[0].day
            } else {
              const temp = checkInCheckOutDate[0].split("-")
              checkInDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let checkOutDate;
            if (checkInCheckOutDate[1].year) {
              checkOutDate = checkInCheckOutDate[1].year + "-" + (checkInCheckOutDate[1].monthIndex + 1) + "-" + checkInCheckOutDate[1].day
            } else {
              const temp = checkInCheckOutDate[1].split("-")
              checkOutDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!room2) {
              return setErrors({ "room": "Requerido" })
            }
            if (!checkInCheckOutDate || checkInCheckOutDate.length !== 2) {
              return setErrors({ "checkInCheckOutDate": "Requerido" })
            }

            const date1 = new moment(checkInDate, "YYYY-MM-DD")
            const date2 = new moment(checkOutDate, "YYYY-MM-DD")
            const date3 = new moment(individualFile.startDate, "DD-MM-YYYY")
            const date4 = new moment(individualFile.endDate, "DD-MM-YYYY")
            const diff1 = date3.diff(date1, 'days');
            const diff2 = date4.diff(date2, 'days');
            if (diff1 > roomSelected.daysBefore) {
              return Swal.fire("Habitacion", "Solo se puede reservar hasta " + roomSelected.daysBefore + " dia(s) antes", "error");
            }
            if (diff2 < (roomSelected.daysAfter * -1)) {
              return Swal.fire("Habitacion", "Solo se puede reservar hasta " + roomSelected.daysAfter + " dia(s) despues", "error");
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addIndividualRoom(individualFile.id, room2.value, quantity, checkInDate, checkOutDate,
              success));
          })}
          validationSchema={
            Yup.object({
              quantity: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-8 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hospedaje:*' name="room" options={rooms} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("room", e);
                            setRoomSelected({
                              daysBefore: e.daysBefore, daysAfter: e.daysAfter, publicPrice:
                                e.publicPrice
                            });
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Cantidad:' name='quantity' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("quantity", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Entrada y Salida:*' name="checkInCheckOutDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("checkInCheckOutDate", e);
                            if (!e || e.length === 2) {
                              const date1 = new moment(e[0].day + "-" + (e[0].monthIndex + 1) + "-" + e[0].year, "DD-MM-YYYY")
                              const date2 = new moment(e[1].day + "-" + (e[1].monthIndex + 1) + "-" + e[1].year, "DD-MM-YYYY")
                              setDaysNights({ days: date2.diff(date1, 'days') + 1, nights: date2.diff(date1, 'days') })
                            }
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Noches:' mode='horizontal' value={daysNights.nights} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Dias:' mode='horizontal' value={daysNights.days} />
                      </div>
                    </div>                    
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel84 label="Misma Tarifa Dias Antes:" value={roomSelected.daysBefore} mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel84 label="Misma Tarifa Dias Despues:" value={roomSelected.daysAfter} mode="horizontal" />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
