import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { LodgingTypeTable } from './LodgingTypeTable';
import { getLodgingTypeList } from '../../../store/slices/catalogue/thunks';
import { AddLodgingType } from './AddLodgingType';

export const LodgingTypeScreen = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  
  useEffect(() => {
    dispatch(getLodgingTypeList())
  }, [])

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" 
          onClick={() => setAdd(true)}>Agregar Tipo De Hospedaje</button>
        <AddLodgingType isOpen={add} setIsOpen={setAdd} />
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Tipo de hospedaje</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <LodgingTypeTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
