import { useState } from "react";
import styles from './Documents.module.css'
import { AddContract } from "./AddContract";
import { AddModificationPolicy } from "./AddModificationPolicy";
import { AddCancellationPolicy } from "./AddCancellationPolicy";
import { DeleteContract } from "./DeleteContract";
import { DeleteModificationPolicy } from "./DeleteModificationPolicy";
import { DeleteCancellationPolicy } from "./DeleteCancellationPolicy";
import { downloadContract, downloadCancellationPolicy, downloadModificationPolicy } from "../../../store/slices/groupFiles/thunks";
import { useDispatch } from "react-redux";



export const Documents = ({ groupFile }) => {
  const dispatch = useDispatch();

  const [addContract, setAddContract] = useState(false);
  const [addModificationPolicy, setAddModificationPolicy] = useState(false);
  const [addCancellationPolicy, setAddCancellationPolicy] = useState(false);
  const [deleteContract, setDeleteContract] = useState(false);
  const [deleteModificationPolicy, setDeleteModificationPolicy] = useState(false);
  const [deleteCancellationPolicy, setDeleteCancellationPolicy] = useState(false);

  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20 }}>        
        {
          groupFile.contract 
          ? <div>
            <button className="btn btn-danger btn-icon" onClick={() => setDeleteContract(true)} >Eliminar Contrato</button>
            <label className={`${styles.download}`} onClick={() => dispatch(downloadContract(groupFile.contract))}>Descargar Contrato</label>
          </div>
          : <button className="btn btn-light btn-icon"
          onClick={() => setAddContract(true)} >Agregar Contrato</button>
        }
      </div>
      <AddContract groupFile={groupFile} isOpen={addContract} setIsOpen={setAddContract} />
      <DeleteContract groupFile={groupFile} isOpen={deleteContract} setIsOpen={setDeleteContract} />

      <div style={{ marginTop: 20, marginBottom: 20 }}>        
        {
          groupFile.modificationPolicy 
          ? <div>
            <button className="btn btn-danger btn-icon" onClick={() => setDeleteModificationPolicy(true)} >Eliminar Politica De Modificacion</button>
            <label className={`${styles.download}`} onClick={() => dispatch(downloadModificationPolicy(groupFile.modificationPolicy))}>Descargar Politica De Modificacion</label>
          </div>
          : <button className="btn btn-light btn-icon"
          onClick={() => setAddModificationPolicy(true)} >Agregar Politica De Modificacion</button>
        }
      </div>
      <AddModificationPolicy groupFile={groupFile} isOpen={addModificationPolicy} setIsOpen={setAddModificationPolicy} />
      <DeleteModificationPolicy groupFile={groupFile} isOpen={deleteModificationPolicy} setIsOpen={setDeleteModificationPolicy} />

      <div style={{ marginTop: 20, marginBottom: 20 }}>        
        {
          groupFile.cancellationPolicy 
          ? <div>
            <button className="btn btn-danger btn-icon" onClick={() => setDeleteCancellationPolicy(true)} >Eliminar Politica De Cancelacion</button>
            <label className={`${styles.download}`} onClick={() => dispatch(downloadCancellationPolicy(groupFile.cancellationPolicy))}>Descargar Politica De Cancelacion</label>
          </div>
          : <button className="btn btn-light btn-icon"
          onClick={() => setAddCancellationPolicy(true)} >Agregar Politica De Cancelacion</button>
        }
      </div>
      <AddCancellationPolicy groupFile={groupFile} isOpen={addCancellationPolicy} setIsOpen={setAddCancellationPolicy} />
      <DeleteCancellationPolicy groupFile={groupFile} isOpen={deleteCancellationPolicy} setIsOpen={setDeleteCancellationPolicy} />
    </>
  )
}
