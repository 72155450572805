import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLodgingGroupList, setRoomLodgingGroupList } from '../../../store/slices/groupFiles/groupFilesSlice';
import { getLoadingGroup, getRoomlodgingGroupList } from '../../../store/slices/groupFiles/thunks';
import { AddRoom } from './AddRoom';
import { RoomDetails } from './RoomDetails';

export const Rooms = ({ groupFile }) => {
  const dispatch = useDispatch();
  const { roomLodgingGroupList } = useSelector((state) => state.groupFiles);
  const [addRoom, setAddRoom] = useState(false);

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getLoadingGroup(groupFile.id))
    }

    return () => {
      dispatch(setLodgingGroupList([]));
    }
  }, [groupFile.id])
  useEffect(() => {
    if (groupFile.id) {
      dispatch(getRoomlodgingGroupList(groupFile.id))
    }

    return () => {
      dispatch(setRoomLodgingGroupList([]));
    }
  }, [groupFile.id])

  return (
    <div>
      <button className="btn btn-light btn-icon" style={{ marginTop: 20 }}
        onClick={() => setAddRoom(true)} >Agregar Habitacion</button>
      <AddRoom groupFile={groupFile} isOpen={addRoom} setIsOpen={setAddRoom} />


      {
        roomLodgingGroupList.map(lodging => (
          <RoomDetails key={lodging.id} lodging={lodging} groupFile={groupFile} />
        ))
      }
    </div>
  )
}
