import React from 'react'
import { AdvancePayment } from './AdvancePayment'
import { MasterSale } from './MasterSale'
import { RejectedPayment } from './RejectedPayment'

export const Details = ({info}) => {
  return (
    <div key={info.id} style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div style={{ fontSize: 22 }}>Expediente #{info.id}</div>
      <AdvancePayment  masterSale={info.masterSale} code={info.code} paydayLimit={info.paydayLimit} paydayLimitDays={info.paydayLimitDays} />
      <RejectedPayment masterSale={info.masterSale} />
      <MasterSale masterSale={info.masterSale} />
    </div>
  )
}
