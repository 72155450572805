import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styles from './RoomingDetails.module.css'
import { useDispatch } from 'react-redux';
import { EditGuest } from "./EditGuest";
import * as colors from '../../../../const/colors'
import { EditDescription } from "./EditDescription";
import { EditReserve } from "./EditReserve";
import { bookingCoupon } from "../../../../store/slices/individualFiles/thunks";

export const RoomingDetails = ({ room, individualFile, index }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState({});
  const [editNotes, setEditNotes] = useState(false);
  const [editReserve, setEditReserve] = useState(false);

  const edit2 = (item) => {
    setEdit(true);
    setItem(item)
  }


  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div className={`${styles.title}`}>Habitacion #{index + 1} - {room.room}</div>
          {
            individualFile.status == "Pagado"
            && <button className={`btn text-white btn-secondary`}
              onClick={() => dispatch(bookingCoupon(room.id, false))} >Cupon</button>
          }
          </div>
        <div style={{ display: "flex" }}>
          <div className={`${styles.notes}`} style={{ color: colors.title }}>Notas: </div>
          {
            room.notes
              ? <div className={`${styles.edit} ${styles.notes}`} onClick={() => setEditNotes(true)}>{room.notes}</div>
              : <div className={`${styles.edit} ${styles.notes}`} onClick={() => setEditNotes(true)}>Agregar Notas</div>
          }
        </div>
        <div style={{ display: "flex" }}>
          <div className={`${styles.notes}`} style={{ color: colors.title }}>Reserva: </div>
          {
            room.reserve
              ? <div className={`${styles.edit} ${styles.reserve}`} onClick={() => setEditReserve(true)}>{room.reserve}</div>
              : <div className={`${styles.edit} ${styles.reserve}`} onClick={() => setEditReserve(true)}>Agregar Reserva</div>
          }
        </div>

        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Categoria</th>
                <th>Fecha De Nacimiento</th>
                <th>Años</th>
                <th>Meses</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {
                room.guests.map(item => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td>{item.birthdate}</td>
                    <td>{item.years}</td>
                    <td>{item.months}</td>
                    <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(item)}></i>
                    </td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <EditGuest guest={item} individualFile={individualFile} isOpen={edit} setIsOpen={setEdit} />
        <EditDescription room={room} individualFile={individualFile} isOpen={editNotes} setIsOpen={setEditNotes} />
        <EditReserve room={room} individualFile={individualFile} isOpen={editReserve} setIsOpen={setEditReserve} />
      </div>
    </>
  )
}
