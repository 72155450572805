import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AddReminder } from './AddReminder';
import { ReminderTable } from './ReminderTable';
import { FormSearchReminder } from './FormSearchReminder';
import { getUsersList } from '../../../store/slices/general/thunks';
import { setReminderFilter, setReminderList } from '../../../store/slices/catalogue/catalogueSlice';

export const ReminderScreen = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);

  useEffect(() => {
    dispatch(getUsersList())

    return () => {
      dispatch(setReminderList([]))
      dispatch(setReminderFilter({user:0, date: undefined}));
    }
  }, [dispatch])

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white"
          onClick={() => setAdd(true)}>Agregar Recordatorio</button>
        <AddReminder isOpen={add} setIsOpen={setAdd} />
      </div>

      <FormSearchReminder />
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Recordatorios</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <ReminderTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
