import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Header } from '../Header';
import { Details } from './Details';

export const SpecialRequestScreen = () => {
  const navigate = useNavigate();
  const { info } = useSelector((state) => state.group);  

  return (
    <div>      
      <Header info={info} />          
      <Details info={info} />
    </div>
  )
}
