import { useDispatch, useSelector } from 'react-redux';
import styles from './Header.module.css'
import { hotelImages, getPaymentsTutorial } from '../../store/slices/guest/thunks';
import { useState } from 'react';
import { ViewImages } from './ViewImages';
import {} from '../ui/Gallery.css';
import { PaymentsTutorial } from '../ui/tutorials/payment/PaymentsTutorial';


export const Header = ({ info }) => {
  const dispatch = useDispatch();
  const [ viewImages, setViewImages ] = useState(false);
  const [ viewPaymentsTutorial, setViewPaymentsTutorial ] = useState(false);
  const { hotelImages: hotelImages2} = useSelector((state) => state.guest);
  const { paymentsTutorial } = useSelector((state) => state.tutorials);
  

  const showImages = () => {
    dispatch(hotelImages(info.code));
    setViewImages(true);
  }

  const showPaymentsTutorial = () => {
    dispatch(getPaymentsTutorial());
    setViewPaymentsTutorial(true);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className={`${styles.title}`}>Grupo: {info.group2}</div>
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Cliente:</label><label>{info.customer}</label>
          </div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Destino:</label><label>{info.tags}</label>
          </div>
        </div>
        <div>
          <button className={`${styles.images} btn btn-info`} onClick={showImages}>Imagenes de hotel</button>
          <button className={`${styles.images} btn btn-info`} onClick={showPaymentsTutorial}>Tutorial de pago</button>
        </div>
      </div>      
      <ViewImages images={hotelImages2} isOpen={viewImages} setIsOpen={setViewImages} />
      <PaymentsTutorial paymentsTutorial={paymentsTutorial} isOpen={viewPaymentsTutorial} setIsOpen={setViewPaymentsTutorial} />
    </div>
  )
}
