import { useState } from 'react';
import { Row } from 'react-bootstrap'
import { currencyFormat } from '../../helpers/numericFields'
import styles from './LodgingDetails.module.css'
import { EditLodging } from './EditLodging';
import { AddRoom } from './AddRoom';
import { RoomDetails } from './RoomDetails';

export const LodgingDetails = ({ lodging, index }) => {
  const [editLodging, setEditLodging] = useState(false);
  const [addRoom, setAddRoom] = useState(false);

  return (
    <>
      <Row className={styles.container}>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Hotel:</div>
        <div className={`col-lg-4 col-md-4 ${styles.edit}`} onClick={() => setEditLodging(true)}>{lodging.hotel}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Mayorista:</div>
        <div className="col-lg-4 col-md-4">{lodging.wholesaler}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Entrada:</div>
        <div className="col-lg-4 col-md-4">{lodging.checkInDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Salida:</div>
        <div className="col-lg-4 col-md-4">{lodging.checkOutDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Estancia:</div>
        <div className="col-lg-4 col-md-4">{lodging.days} dia(s) / {lodging.nights} noche(s)</div>
        <div className="col-lg-6 col-md-6"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Notas:</div>
        <div className="col-lg-10 col-md-10">{lodging.notes}</div>
        <div style={{ height: 10 }}></div>
        {
          lodging.rooms.map(room => (
            <RoomDetails key={room.id} room={room} lodging={lodging} />
          ))
        }
        <div className={`col-lg-7 col-md-7`}></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle} ${styles.subTotal}`}>Sub Total:</div>
        <div className={`col-lg-3 col-md-3 ${styles.subTotal}`}>{lodging.currency} {currencyFormat(lodging.rooms.reduce((a,b) => a+b.rate,0))}</div>
        <div style={{ height: 10 }}></div>
        <button className="btn btn-light" onClick={() => setAddRoom(true)}>+Habitacion</button>
        <AddRoom lodging={lodging} isOpen={addRoom} setIsOpen={setAddRoom} />
        <div className={`col-12 ${styles.line}`}></div>

      </Row>
      <EditLodging lodging={lodging} isOpen={editLodging} setIsOpen={setEditLodging} />
    </>
  )
}
