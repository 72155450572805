import { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { useSelector, useDispatch } from 'react-redux';
import { setInfoIndividualSale } from "../../../store/slices/groupFiles/groupFilesSlice";
import { MyCheckBox3 } from "../../ui/controls/MyCheckBox3";

export const AddCustomer = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { customersList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Venta Individual
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            customer: undefined,
            owner: false,
          }}
          onSubmit={(({ customer, owner }, { resetForm, setErrors }) => {

            if (!customer) {
              return setErrors({ "customer": "Requerido" })
            }

            dispatch(setInfoIndividualSale({ customer, owner }));
            resetForm();
            setIsOpen(false);
          })}
          validationSchema={
            Yup.object({

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Cliente:*' name="customer" options={customersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("customer", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox3 label="Pertenece al dueño:" name="owner" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
