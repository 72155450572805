import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './MasterSaleDetails.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { downloadPayments, openCloseMasterSale } from '../../../store/slices/files/thunks';

export const MasterSaleDetails = ({ masterSale, file }) => {
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const openCloseMasterSale2 = (status) => {
    dispatch(openCloseMasterSale(masterSale.id, status, file.id))
  }

  const downloadPayments2 = () => {
    dispatch(downloadPayments(file.id))
  }

  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Fechas</th>
                <th>Mayorista</th>
                <th>Boleto</th>
                <th>Reserva</th>
                <th>Base</th>                
                <th>Cuota Servicio</th>
                <th>Impuestos</th>
                <th>Otros</th>
                <th>Tarifa Publica</th>
                <th>Tarifa Neta</th>
              </tr>
            </thead>
            <tbody>
              {
                masterSale.air.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.airline}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.fee)}</td>
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(0)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                  </tr>))
              }
              {
                masterSale.lodging.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.rate - item.netPriceTotal)}</td>
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(0)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                  </tr>))
              }
              {
                masterSale.cruise.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.company} - {item.cabin}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.rate - item.netPriceTotal)}</td>
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(item.tip)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                  </tr>))
              }
              {
                masterSale.service.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.service}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.rate - item.netPriceTotal)}</td>
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(0)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <div className={styles.total}>Total: {
          currencyFormat(
            masterSale.air.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.lodging.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.cruise.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.service.reduce((a, b) => a += (b.publicPriceTotal), 0) 
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon"
                onClick={() => downloadPayments2()} >Descargar pagos</button>        
        {
          masterSale.isOpen
            ? <button className="btn btn-danger btn-icon"
                onClick={() => openCloseMasterSale2(false)} >Cerrar Venta</button>            
            : (
              role === "DIRECTOR" 
              ? <button className="btn btn-danger btn-icon"
                onClick={() => openCloseMasterSale2(true)} >Abrir Venta</button>
              : <></>
            )
        }
      </div>
    </>
  )
}
