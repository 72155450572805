import { createSlice } from '@reduxjs/toolkit';

export const catalogueSlice = createSlice({
  name: 'catalogue',
  initialState: {
    airlinesList: [],
    customersList: [],    
    hotelsList: [],
    hotelImagesList: [],
    servicesList: [],
    wholesalersList: [],
    contactWayList: [],
    lodgingTypeList: [],
    rolesList: [],
    usersList: [],
    calendarEvents: {
      isLoading:true,
      events:[]
    },
    reminderListToday: [],
    reminderList: [],    
    reminderFilter: {
      user:0,
      date: undefined
    },    
  },
  reducers: {
    setAirlines: (state, action) => {
      state.airlinesList = action.payload;
    },
    setCustomers: (state, action) => {
      state.customersList = action.payload;
    },    
    setHotels: (state, action) => {
      state.hotelsList = action.payload;
    },
    setHotelImages: (state, action) => {
      state.hotelImagesList = action.payload;
    },
    setServices: (state, action) => {
      state.servicesList = action.payload;
    },
    setWholesalers: (state, action) => {
      state.wholesalersList = action.payload;
    },
    setContactWay: (state, action) => {
      state.contactWayList = action.payload;
    },
    setLodgingType: (state, action) => {
      state.lodgingTypeList = action.payload;
    },
    setRolesList: (state, action) => {
      state.rolesList = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.calendarEvents = {
        isLoading:action.payload.isLoading,
        events: action.payload.events
      }
    },
    setReminderListToday: (state, action) => {
      state.reminderListToday = action.payload;
    },
    setReminderList: (state, action) => {
      state.reminderList = action.payload;
    },
    setReminderFilter: (state, action) => {
      state.reminderFilter = action.payload;
    },
  }
});


// Action creators are generated for each case reducer function
export const { setCustomers, setAirlines, setHotels, setServices, setWholesalers, setContactWay, 
  setLodgingType, setRolesList, setUsersList, setCalendarEvents, setReminderListToday, setReminderList, 
  setReminderFilter, setHotelImages } = catalogueSlice.actions;