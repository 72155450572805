import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { useSelector, useDispatch } from 'react-redux';
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { deletePassengerFile, editPassengerFile } from "../../../store/slices/files/thunks";
import { MySelect } from "../../ui/controls/MySelect";

export const EditPassenger = ({ passenger, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { customersList } = useSelector((state) => state.general);

  const delete2 = () => {
    const success = () => {
      setIsOpen(false);
    }
    dispatch(deletePassengerFile(passenger.id, passenger.idFile, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Pasajero
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            customer: undefined,
            name: passenger.name,
            lastName: passenger.lastName,
            mothersLastName: passenger.mothersLastName,
            relationship: passenger.relationship,
            birthdate: passenger.birthdate,
          }}
          onSubmit={(({ name, lastName, mothersLastName, relationship, birthdate },
            { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }
            dispatch(editPassengerFile(passenger.id, name, lastName, mothersLastName, relationship,
              birthdate ? birthdate.year + "-" + (birthdate.monthIndex + 1) + "-" + birthdate.day : undefined,
              passenger.idFile, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              lastName: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Cliente:*' name="customer" options={customersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("customer", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-0"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre(s):*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Apellido Paterno*:' name='lastName' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Apellido Materno:' name='mothersLastName' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Parentesco:' name='relationship' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de nacimiento' name="birthdate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("birthdate", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
