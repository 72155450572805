import { RejectedPaymentDetails } from './RejectedPaymentDetails';

export const RejectedPayment = ({masterSale}) => {

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos rechazados de cliente</div>
      <RejectedPaymentDetails masterSale={masterSale} />
    </div>
  )
}
