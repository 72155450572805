import { createSlice } from '@reduxjs/toolkit';

export const representativeSlice = createSlice({
  name: 'representative',
  initialState: {    
    files: [], 
  },
  reducers: {   
    setFiles: (state, action) => {
      state.files = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setFiles
  } = representativeSlice.actions;