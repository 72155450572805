import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as colors from '../../../const/colors'
import { currencyFormat } from '../../../helpers/numericFields';
import { removeRoomIndividualSale } from '../../../store/slices/groupFiles/groupFilesSlice';
import { addIndividualFile } from '../../../store/slices/individualFiles/thunks';
import { AddRoom } from './AddRoom'
import { EditCustomer } from './EditCustomer';
import styles from './IndividualSaleDetails.module.css'

export const IndividualSaleDetails = ({ individualSale, groupFile }) => {
  const [addRoom, setAddRoom] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const dispatch = useDispatch();

  const addFile = () => {
    const success = () => {

    }

    dispatch(addIndividualFile(individualSale.customer.value, individualSale.owner, groupFile.id,
      individualSale.rooms.map(item => ({
        idRoom: item.room.value, quantity: item.quantity, checkInDate: item.checkInDate,
        checkOutDate: item.checkOutDate
      })),
      success));
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div style={{display:"flex", flexDirection:"row"}}>
        <div className={`${styles.title} ${styles.edit}`}
          style={{ color: colors.title, marginLeft: 5 }}
          onClick={() => setEditCustomer(true)}
        >{individualSale.customer.label}</div>
        <div className={`${styles.title}`} style={{marginLeft:20}}>{individualSale.owner ? " Pertenece al dueño" : ""}</div>
      </div>
      <div className="d-flex justify-content-between" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-light btn-icon"
          onClick={() => setAddRoom(true)} >Agregar Partida Hospedaje</button>
        <button className="btn btn-success btn-icon"
          onClick={addFile} >Crear Expediente</button>
      </div>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Habitacion</th>
              <th>Fechas</th>
              <th>Base</th>
              <th>SubTotal</th>
              <th>Cancelar</th>
            </tr>
          </thead>
          <tbody>
            {
              individualSale.rooms.map(item => (
                <tr key={item.room.label + item.checkInDate + item.checkOutDate}>
                  <td style={{ textAlign: "center" }}>{item.quantity}</td>
                  <td>{item.room.label}</td>
                  <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                  <td>{currencyFormat(item.room.publicPrice * item.nights)}</td>
                  <td>{currencyFormat(item.quantity * item.room.publicPrice * item.nights)}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-x ${styles.cancel}`} onClick={
                      () => dispatch(removeRoomIndividualSale(item.room.value))}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <div className={styles.total}>Total: {
        currencyFormat(
          individualSale.rooms.reduce((a, b) => a += (b.quantity * b.room.publicPrice * b.nights), 0)
        )}
      </div>
      <AddRoom individualSale={individualSale} groupFile={groupFile} isOpen={addRoom} setIsOpen={setAddRoom} />
      <EditCustomer individualSale={individualSale} isOpen={editCustomer} setIsOpen={setEditCustomer} />
    </div>
  )
}
