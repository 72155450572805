import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRoomingList } from '../../../../store/slices/individualFiles/individualFilesSlice';
import { getRoomingListByIndividualFile } from '../../../../store/slices/individualFiles/thunks';
import { RoomingDetails } from './RoomingDetails';



export const Rooming = ({ individualFile }) => {
  const dispatch = useDispatch();
  const { roomingList } = useSelector((state) => state.individualFiles);
  
  useEffect(() => {
    if (individualFile.id) {
      dispatch(getRoomingListByIndividualFile(individualFile.id))
    }

    return () => {
      dispatch(setRoomingList([]));
    }
  }, [individualFile.id])

  return (
    <div>
      {
        roomingList.map((item, index) => (
          <RoomingDetails key={item.id} room={item} index={index} individualFile={individualFile} />
        ))
      }      
    </div>
  )
}
