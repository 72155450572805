import { useState } from "react";
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import styles from './AirportHotelDetails.module.css'
import { EditAirportHotel } from "./EditAirportHotel";
import { Link } from "react-router-dom";

export const AirportHotelDetails = ({ pendingAirportHotel, pendingAirportHotel2 }) => {
  const [editAirportHotel2, setEditAirportHotel2] = useState(false);
  const [requestSelected, setEditRequestSelected] = useState({});
  let row=1;
  const edit2 = (request) => {
    setEditAirportHotel2(true);
    setEditRequestSelected(request)
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Exp</th>              
              <th>Vuelo</th>
              <th>Aerolinea</th>
              <th>Fecha de llegada vuelo</th>
              <th>Personas</th>
              <th>Nombres</th>
              <th>Terminal</th>
              <th>Modo</th>
              <th>Estado</th>
              <th>Notas</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              pendingAirportHotel.map((request) => (
                <tr key={request.id}>
                  <td>{row++}</td>                  
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expedienteGrupo?id=${request.idGroup}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{request.idGroup}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{request.flight}</td>
                  <td>{request.airline}</td>                  
                  <td>{request.date}</td>
                  <td>{request.people}</td>
                  <td>{request.names}</td>
                  <td>{request.terminal}</td>
                  <td>{request.mode}</td>
                  <td>{request.statusRequest}</td>
                  <td>{request.notes}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(request)}></i>
                  </td>
                </tr>))
            }
            {
              pendingAirportHotel2.map((request) => (
                <tr key={request.id}>
                  <td>{row++}</td>                  
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expedienteIndividual?id=${request.idIndividualFile}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{request.idIndividualFile}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{request.flight}</td>
                  <td>{request.airline}</td>                  
                  <td>{request.date}</td>
                  <td>{request.people}</td>
                  <td>{request.names}</td>
                  <td>{request.terminal}</td>
                  <td>{request.mode}</td>
                  <td>{request.statusRequest}</td>
                  <td>{request.notes}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(request)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <EditAirportHotel request={requestSelected}
        isOpen={editAirportHotel2} setIsOpen={setEditAirportHotel2} />
    </div>
  )
}
