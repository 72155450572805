import React, { useState } from 'react'
import { AddAdvancePayment } from './AddAdvancePayment';
import { AdvancePaymentDetails } from './AdvancePaymentDetails';

export const AdvancePayment = ({masterSale, code, groupCode, paydayLimit, paydayLimitDays}) => {
  const [addAdvancePayment, setAddAdvancePayment] = useState(false);

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos realizados</div>     
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {
          paydayLimitDays < -15
          ? <div style={{ fontSize: 20, color: 'orangered' }}>Tu fecha límite de pago es {paydayLimit}, ya no es posible realizar pagos.</div>
          : <button className="btn btn-primary btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddAdvancePayment(true)} >Mandar Pago Realizado</button>
        }
      </div>

      <AdvancePaymentDetails masterSale={masterSale} code={code} />

      <AddAdvancePayment masterSale={masterSale} code={code} groupCode={groupCode} isOpen={addAdvancePayment} setIsOpen={setAddAdvancePayment} />
    </div>
  )
}
