import { ErrorMessage, useField } from "formik"
import styles from './MyDatePicker.module.css'
import gregorian_es from "../../../const/gregorian_es"
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";


export const MyTimePicker = ({ label, mode = "vertical", ...props }) => {

  const [field] = useField(props)

  return <>
    {
      mode === "horizontal" &&
      <div className="mb-3 row">
        <label className='col-form-label col-4' htmlFor={props.id || props.name} >{label}</label>
        <div className="col-8">
          <DatePicker
            className={`form-control fc-datepicker ${styles.select}`}            
            locale={gregorian_es}
            format="DD/MM/YYYY hh:mm A"
            plugins={[
              <TimePicker position="bottom" hideSeconds  />
            ]} 
            {...field} {...props}
          />
          <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
        </div>
      </div>
    }
    {
      mode === "vertical" &&
      <div className="mb-3">
        <label className='form-label' htmlFor={props.id || props.name} >{label}</label>
        <DatePicker
          className="form-control fc-datepicker"
          multiple={false}
          numberOfMonths={1}
          locale={gregorian_es}
          format="DD/MM/YYYY hh:mm A"
          {...field} {...props}
        />
        <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
      </div>
    }
  </>
}
