import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getContactWayList } from '../../../store/slices/catalogue/thunks';
import { AddContactWay } from './AddContactWay';
import { ContactWayTable } from './ContactWayTable';

export const ContactWayScreen = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  
  useEffect(() => {
    dispatch(getContactWayList())
  }, [])

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" 
          onClick={() => setAdd(true)}>Agregar Medio De Contacto</button>
        <AddContactWay isOpen={add} setIsOpen={setAdd} />
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Medios De Contacto</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <ContactWayTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
