import React from "react";
import {
  Card,
  Row,
  Button,
} from "react-bootstrap";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useSelector } from 'react-redux';
import { getFilesList } from "../../store/slices/files/thunks";
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../ui/controls/MyTextInput";
import { onlyIntegers } from "../../helpers/numericFields";
import { MySelect } from "../ui/controls/MySelect";

export const FormSearchFile = () => {
  const dispatch = useDispatch();
  const { filterMonthList } = useSelector((state) => state.general);

  return (
    <div>

      <Row>
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <Card>
            <Card.Header>
              <h4 className="card-title">Buscar Expedientes</h4>
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  file: '',
                  customer: "",
                  reserve: "",
                  month: undefined,
                  tags: '',
                }}
                onSubmit={(({ file, customer, reserve, month, tags },
                  { resetForm, setErrors }) => {

                  dispatch(getFilesList(file, customer, reserve, month ? month.value : undefined, tags));

                })}
                validationSchema={
                  Yup.object({
                  })
                }
              >

                {
                  ({ setFieldValue }) => (
                    <Form>
                      <Row>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MyTextInput label='Por #:' name='file' mode='horizontal'
                              onChange={(e) => {
                                if (!onlyIntegers(e)) {
                                  return
                                }
                                setFieldValue("file", e.target.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MyTextInput label='Por cliente:' name='customer' mode='horizontal' />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MyTextInput label='Por reserva:' name='reserve' mode='horizontal' />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MySelect label='Por mes:*' name="month" options={[{value:"",label:""},...filterMonthList]} mode='horizontal'
                              singleSelect="true"
                              onChange={e => {
                                setFieldValue("month", e);
                              }} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MyTextInput label='Por destino:' name='tags' mode='horizontal' />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className=" row mb-4">
                            <Button variant="primary" type="submit">
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  )
                }
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </div>
  );
}
