import Swal from "sweetalert2";
import { setPaymentsTutorial } from "./tutorialsSlice";
import { viajesApi } from "../../../api/viajesApi";



export const getPaymentsTutorial = () => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/tutorials/paymentsTutorial");
      dispatch(setPaymentsTutorial(resp.data.results));
    } catch (error) {
      dispatch(setPaymentsTutorial([]));
    }
  }
}

export const addPaymentsTutorialImage = (image, text, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("text", text);
      if (image)
        form.append("image", image, image.Name);      

      const resp = await viajesApi.post("/tutorials/addPaymentsImage", form);
      success();
      dispatch(getPaymentsTutorial());
      Swal.fire("Tutoriales", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Tutoriales", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentsTutorialImage = (image) => {
  return async (dispatch, getState) => {
    try {      
      await viajesApi.delete("/tutorials/deletePaymentsImage",{
        params:{ image }
      });
      dispatch(getPaymentsTutorial());
    } catch (error) {

    }
  }
}

export const editPaymentsTutorialImage = (id, image, text, success) => {
  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("text", text);
      if (image)
        form.append("image", image, image.Name);      

      const resp = await viajesApi.post("/tutorials/editPaymentsImage", form);

      success();
      dispatch(getPaymentsTutorial());
      Swal.fire("Tutoriales", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Tutoriales", error.response.data.msg, "error");
    }
  }
}