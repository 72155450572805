import { ErrorMessage, useField } from "formik"
import styles from './MyFileInput.module.css'

export const MyFileInput29 = ({ label, mode="vertical", ...props }) => {

  const [field] = useField(props)

  return <>
    {
      mode === "horizontal" &&
      <div className="mb-3 row">
        <label className='col-form-label col-2' htmlFor={props.id || props.name} >{label}</label>
        <div className="col-9">
          <input className="form-control" type="file" {...field} {...props} ref={props.ref2} />
          <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
        </div>        
      </div>
    }
    {
      mode === "vertical" &&
      <div className="mb-3">
        <label className='form-label' htmlFor={props.id || props.name} >{label}</label>
        <input className="form-control" type="file" {...field} {...props} ref={props.ref2} />
        <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
      </div>
    }
  </>
}
