import { useState } from 'react';
import { currencyFormat } from '../../helpers/numericFields'
import { EditRoom } from './EditRoom';
import styles from './RoomDetails.module.css'

export const RoomDetails = ({ room, lodging}) => {
  const [editRoom, setEditRoom] = useState(false);
  return (
    <>    
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Habitacion:</div>      
      <div className={`col-lg-5 col-md-5 ${styles.edit}`} onClick={() => setEditRoom(true)}>{room.room}</div>
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Publica:</div>
      <div className={`col-lg-3 col-md-3`}>{room.currency} {currencyFormat(room.rate)}</div>
      <div className={`col-lg-7 col-md-7`}></div>            
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Neta:</div>
      <div className={`col-lg-3 col-md-3`}>{room.currency} {currencyFormat(room.netPrice)}</div>
      <EditRoom room={room} idQuote={lodging.idQuote} isOpen={editRoom} setIsOpen={setEditRoom} />
    </>
  )
}
