import React, { useState } from 'react'
import { IndividualFilesDetails } from './IndividualFilesDetails';

export const IndividualFiles = ({files}) => {

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Expedientes</div>

      <IndividualFilesDetails files={files} />
    </div>
  )
}
