import { createSlice } from '@reduxjs/toolkit';

const initialStateRommingList = {
  roomingList: [],
  summary: [],
  control: []
}

const initialStateMasterSale = {
  rooms: [],
  services: [],
  advancePayments: [],
  rejectedPayments: [],
  accountsPayable: [],
  paymentsToWholesalerRooms: [], 
  paymentsToWholesalerServices: [],
  customerPayments: [], 
  isOpen: 1,
}

export const groupFilesSlice = createSlice({
  name: 'groupFiles',
  initialState: {
    code: "",
    groupFilesList: [],
    additionalList: [],
    groupFile: {},
    lodgingGroupList: [],
    roomLodgingGroupList: [],
    masterSaleGroup: initialStateMasterSale,
    individualSale: { rooms: [] },
    individualFilesList: [],
    roomingList: initialStateRommingList,
    roomingListDetails: [],
    pendingPayments: [],    
    pendingAirportHotel: [],
    pendingHotelAirport: [],
    transportChurch:[],
    paymentScheme:[],
    weedingPass:[],
    waitingList:[],
    pendingSpecialRequest: [],
  },
  reducers: {
    setAdditionalList: (state, action) => {
      state.additionalList = action.payload;
    },
    setGroupFilesList: (state, action) => {
      state.groupFilesList = action.payload;
    },
    setGroupFile: (state, action) => {
      state.groupFile = action.payload;
    },
    setLodgingGroupList: (state, action) => {
      state.lodgingGroupList = action.payload;
    },
    setRoomLodgingGroupList: (state, action) => {
      state.roomLodgingGroupList = action.payload;
    },
    setMasterSaleGroup: (state, action) => {
      state.masterSaleGroup = action.payload;
    },
    resetMasterSaleGroup: (state, action) => {
      state.masterSaleGroup = initialStateMasterSale;
    },
    setInfoIndividualSale: (state, action) => {
      state.individualSale = { ...state.individualSale, ...action.payload };
    },
    addRoomIndividualSale: (state, action) => {
      const room = state.individualSale.rooms.find(x => x.room.value === action.payload.room.value);
      if (room) {
        if (room.checkInDate === action.payload.checkInDate && room.checkOutDate === action.payload.checkOutDate) {
          state.individualSale.rooms = state.individualSale.rooms.map(item => (
            (item.room.value === action.payload.room.value &&
              item.checkInDate === action.payload.checkInDate &&
              item.checkOutDate === action.payload.checkOutDate)
              ? { ...item, quantity: item.quantity + action.payload.quantity }
              : item
          ))
        } else {
          state.individualSale.rooms = [...state.individualSale.rooms, action.payload];
        }
      } else {
        state.individualSale.rooms = [...state.individualSale.rooms, action.payload];
      }
    },
    removeRoomIndividualSale: (state, action) => {
      state.individualSale.rooms = state.individualSale.rooms.filter(x => x.room.value !== action.payload)
    },
    clearIndividualSale: (state, action) => {
      state.individualSale = { rooms: [] };
    },
    setIndividualFilesList: (state, action) => {
      state.individualFilesList = action.payload;
    },
    setRoomingList: (state, action) => {
      state.roomingList = action.payload;
    },
    resetRommingList: (state, action) => {
      state.roomingList = initialStateRommingList;
    },
    setRoomingListDetails: (state, action) => {
      state.roomingListDetails = action.payload;
    },
    setPendingPayments: (state, action) => {
      state.pendingPayments = action.payload;
    },    
    setPendingAirportHotel: (state, action) => {
      state.pendingAirportHotel = action.payload;
    },
    setPendingHotelAirport: (state, action) => {
      state.pendingHotelAirport = action.payload;
    },
    setTransportChurch: (state, action) => {
      state.transportChurch = action.payload;
    },    
    setPaymentScheme: (state, action) => {
      state.paymentScheme = action.payload;
    }, 
    setWeedingPass: (state, action) => {
      state.weedingPass = action.payload;
    },
    setWaitingList: (state, action) => {
      state.waitingList = action.payload;
    }, 
    setPendingSpecialRequest: (state, action) => {
      state.pendingSpecialRequest = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setAdditionalList, setGroupFilesList, setGroupFile, setLodgingGroupList,
  setRoomLodgingGroupList, setMasterSaleGroup, setInfoIndividualSale, addRoomIndividualSale,
  removeRoomIndividualSale, clearIndividualSale, setIndividualFilesList, setRoomingList,
  setRoomingListDetails, setPendingPayments, resetRommingList, resetMasterSaleGroup,
  setPendingAirportHotel, setPendingHotelAirport, setTransportChurch, setPaymentScheme,
  setWeedingPass, setWaitingList, setPendingSpecialRequest } = groupFilesSlice.actions;