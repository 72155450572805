import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { currencyFormat } from '../../../helpers/numericFields';
import { resetCustomerPayments, setCustomerPayments } from '../../../store/slices/individualFiles/individualFilesSlice';
import { getCustomerPayments } from '../../../store/slices/individualFiles/thunks';
import { FilesTable } from './FilesTable';
import { PaymentsTable } from './PaymentsTable';

export const CustomerPayments = ({ groupFile }) => {
  const dispatch = useDispatch();
  const { customerPayments } = useSelector((state) => state.individualFiles);
  const { files } = customerPayments;

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getCustomerPayments(groupFile.id));
    }

    return () => {
      dispatch(resetCustomerPayments());
    }
  }, [groupFile.id])


  return (
    <div>

      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-4" style={{
          fontSize: 20, padding: 10, display: "flex",
          flexDirection: "row", justifyContent: "center"
        }}>
          Ventas: {currencyFormat(files.reduce((a, b) => a + b.publicPrice, 0))}
        </div>
        <div className="col-4" style={{ fontSize: 20, textAlign: "center", padding: 10 }}>
          Pagado: {currencyFormat(files.reduce((a, b) => a + b.paid, 0))}
        </div>
        <div className="col-4" style={{ fontSize: 20, textAlign: "center", padding: 10 }}>
          Saldo: {currencyFormat(files.reduce((a, b) => a + b.paid, 0) -
            files.reduce((a, b) => a + b.publicPrice, 0))}
        </div>
      </div>

      <FilesTable />
      <PaymentsTable />

    </div>
  )
}
