import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
// import { groupTravelVoucher } from '../store/slices/files/thunks'

export const Documents = () => {
  const dispatch = useDispatch();

  // const travelVoucher = () => {
  //   dispatch(groupTravelVoucher(5));
  // }

  return (
    <div>
      {/* <Button variant="primary" onClick={travelVoucher}>
        Recibo de viaje
      </Button> */}
    </div>
  )
}
