import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPendingHotelAirport } from '../../../store/slices/groupFiles/thunks';
import { getPendingHotelAirport as getPendingHotelAirport2 } from '../../../store/slices/individualFiles/thunks';
import { HotelAirportDetails } from './HotelAirportDetails';

export const HotelAirport = () => {
  const dispatch = useDispatch();
  const { pendingHotelAirport } = useSelector((state) => state.groupFiles);
  const { pendingHotelAirport: pendingHotelAirport2 } = useSelector((state) => state.individualFiles);
  
  useEffect(() => {
    dispatch(getPendingHotelAirport())
  }, [])

  useEffect(() => {
    dispatch(getPendingHotelAirport2())
  }, [])
  

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Solicitudes pendientes por aprobar</div>
      <HotelAirportDetails pendingHotelAirport={pendingHotelAirport} pendingHotelAirport2={pendingHotelAirport2} />
    </div>
  )
}
