import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyDatePicker } from "../../../ui/controls/MyDatePicker";
import { useDispatch } from 'react-redux';
import { MyTextLabel } from "../../../ui/controls/MyTextLabel";
import { editGuestRoom } from "../../../../store/slices/individualFiles/thunks";
import { MyTextInput } from "../../../ui/controls/MyTextInput";

export const EditGuest = ({ guest, individualFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Huesped En Habitacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: guest.name || "",
            birthdate: guest.birthdate
          }}
          onSubmit={(({ name, birthdate }, { resetForm, setErrors }) => {            
            let birthdate2;
            if (birthdate) {
              if (birthdate.year) {
                birthdate2 = birthdate.year + "-" + (birthdate.monthIndex + 1) + "-" + birthdate.day
              } else {
                const temp = birthdate.split("-")
                birthdate2 = temp[2] + "-" + temp[1] + "-" + temp[0]
              }
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editGuestRoom(guest.id, name, birthdate2, individualFile.id, success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Categoria:' mode='horizontal' value={guest.category} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha De Nacimiento:*' name="birthdate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("birthdate", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
