import { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MySelect } from "../../ui/controls/MySelect";
import { useSelector, useDispatch } from 'react-redux';
import { editCustomer } from "../../../store/slices/catalogue/thunks";
import { getContactWayList } from "../../../store/slices/general/thunks";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";

export const EditCustomer = ({item, isOpen, setIsOpen}) => {
  const dispatch = useDispatch();
  const { contactWayList } = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(getContactWayList());
  }, [])

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Cliente
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            contactWay: {"value": item.idContactWay, "label": item.contactWay},
            name: item.name,
            lastName: item.lastName,
            mothersLastName: item.mothersLastName,
            cellphone: item.cellphone,
            email: item.email,
            telephone: item.telephone,
            whoRecommended: item.whoRecommend,
            contact: item.contact,
            status: item.status === 1 ? true : false,
          }}
          onSubmit={(({ contactWay, name, lastName, mothersLastName, cellphone, email, telephone,
            whoRecommended, contact, status }, { resetForm, setErrors }) => {
            if (!contactWay) {              
              return setErrors({"contactWay":"Requerido"})              
            }

            const success = ()=> {
              resetForm();
              setIsOpen(false)
            }
            
            dispatch(editCustomer(item.id, contactWay.value, name, lastName, mothersLastName, 
              cellphone, email, telephone, whoRecommended, contact, status, success));
          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              lastName: Yup.string()
                .required("Requerido"),
              email: Yup.string()
                .email("Formato de correo invalido")
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Medio:*' name="contactWay" options={contactWayList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("contactWay", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Apellido Paterno:*' name='lastName' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Apellido Materno:' name='mothersLastName' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Celular:' name='cellphone' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Correo Electronico:' name='email' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Telefono:' name='telephone' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='¿Quien Recomendo?:' name='whoRecommended' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Contacto:' name='contact' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Estatus:" name="status" mode='horizontal' style={{fontSize:20, marginTop:8}} />
                      </div>
                    </div>
                  </Row>
                  <div style={{fontSize:13}}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
