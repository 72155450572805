import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { specialRequests } from '../../../store/slices/guest/thunks';
import { SpecialRequestDetails } from './SpecialRequestDetails';
import { AddSpecialRequest } from './AddSpecialRequest';

export const SpecialRequest = ({ code }) => {
  const dispatch = useDispatch();
  const { specialRequests: specialRequests2 } = useSelector((state) => state.guest);
  const [addSpecialRequest, setSpecialRequest] = useState(false);

  useEffect(() => {
    dispatch(specialRequests(code))
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon" style={{ marginTop: 10 }}
          onClick={() => setSpecialRequest(true)} >Crear una solicitud</button>
      </div>

      <SpecialRequestDetails code={code} specialRequests={specialRequests2} />
      <AddSpecialRequest code={code} isOpen={addSpecialRequest} setIsOpen={setSpecialRequest} />      
    </div>
  )
}
