import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getHotelsList } from '../../../store/slices/catalogue/thunks';
import { HotelTable } from './HotelTable';
import { AddHotel } from './AddHotel';

export const HotelScreen = () => {
  const dispatch = useDispatch();
  const [addHotel, setAddHotel] = useState(false);
  
  useEffect(() => {
    dispatch(getHotelsList())
  }, [])

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" 
          onClick={() => setAddHotel(true)}>Agregar Hotel</button>
        <AddHotel isOpen={addHotel} setIsOpen={setAddHotel} />
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Hoteles</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <HotelTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
