import { useQuery } from '../../hooks/useQuery';
import * as colors from '../../const/colors'
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import {
  getAirlinesList, getCurrenciesList, getHotelsList, getWholesalersList,
  getCategoriesList
} from '../../store/slices/general/thunks';
import { getGroupFileById, } from '../../store/slices/groupFiles/thunks';
import { Lodging } from './Lodging/Lodging';
import { Rooms } from './Rooms/Rooms';
import { MasterSale } from './MasterSale/MasterSale';
import { useNavigate } from 'react-router-dom';
import { IndividualSale } from './IndividualFile/IndividualSale';
import { CustomerPayments } from './CustomerPayments/CustomerPayments';
import { Rooming } from './Rooming/Rooming';
import { setGroupFile } from '../../store/slices/groupFiles/groupFilesSlice';
import { SeatingPlanScreen } from './SeatingPlan/SeatingPlanScreen';
import { CourtshipLineScreen } from './CourtshipLine/CourtshipLineScreen';
import { TransportScreen } from './Transport/TransportScreen';
import { AgencyPaymentScheme } from './AgencyPaymentScheme/AgencyPaymentScheme';
import { WeedingPass } from './WeedingPass/WeedingPass';
import { WaitingList } from './WaitingList/WaitingList';

export const GroupFileDetailsScreen = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const id = (query.get("id")) ? query.get("id") : "";
  const { groupFile } = useSelector((state) => state.groupFiles);
  const [optionSelected, setOptionSelected] = useState("Datos Generales")

  useEffect(() => {
    if (id) {
      dispatch(getGroupFileById(id))
    } else {
      navigate("/panel/grupos")
    }
    return () => {
      dispatch(setGroupFile({}));
    }
  }, [id])
  useEffect(() => {
    dispatch(getCurrenciesList());
  }, [])
  useEffect(() => {
    dispatch(getAirlinesList());
  }, [])
  useEffect(() => {
    dispatch(getWholesalersList());
  }, [])
  useEffect(() => {
    dispatch(getCategoriesList());
  }, [])
  useEffect(() => {
    dispatch(getHotelsList());
  }, [])

  return (
    <div>
      <div style={{
        textAlign: "left",
        marginTop: 20,
        fontSize: 25,
        color: colors.title,
      }}>{`Expediente #${id}`}</div>
      <div style={{
        textAlign: "left",
        fontSize: 20,
        color: colors.title,
      }}>{`Grupo: ${groupFile.name}`}</div>

      <div className="d-flex" style={{ marginTop: 20, marginBottom: 20 }} >

        <button className={`btn text-white ${optionSelected === "Datos Generales" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Datos Generales")} >Datos Generales</button>
        <button className={`btn text-white ${optionSelected === "Lodging" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Lodging")} >Hospedaje</button>
        <button className={`btn text-white ${optionSelected === "Rooms" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Rooms")} >Habitaciones</button>
        <button className={`btn text-white ${optionSelected === "MasterSale" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("MasterSale")} >Venta Maestra</button>
        <button className={`btn text-white ${optionSelected === "Files" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Files")} >Expedientes</button>
        <button className={`btn text-white ${optionSelected === "CustomerPayments" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("CustomerPayments")} >Pagos Clientes</button>
        <button className={`btn text-white ${optionSelected === "Rooming" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Rooming")} >Rooming</button>
        <button className={`btn text-white ${optionSelected === "Tables" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Tables")} >Mesas</button>
        <button className={`btn text-white ${optionSelected === "CourtshipLine" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("CourtshipLine")} >Cortejo</button>
        <button className={`btn text-white ${optionSelected === "Transport" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("Transport")} >Transporte</button>
        <button className={`btn text-white ${optionSelected === "PaymentScheme" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("PaymentScheme")} >Esquema De Pagos</button>
        <button className={`btn text-white ${optionSelected === "WeedingPass" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("WeedingPass")} >Weeding Pass</button>
        <button className={`btn text-white ${optionSelected === "WaitingList" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => setOptionSelected("WaitingList")} >Lista De Espera</button>
      </div>
      {optionSelected === "Datos Generales" && <GeneralInfo groupFile={groupFile} />}
      {optionSelected === "Lodging" && <Lodging groupFile={groupFile} />}
      {optionSelected === "Rooms" && <Rooms groupFile={groupFile} />}
      {optionSelected === "MasterSale" && <MasterSale groupFile={groupFile} />}
      {optionSelected === "Files" && <IndividualSale groupFile={groupFile} />}
      {optionSelected === "CustomerPayments" && <CustomerPayments groupFile={groupFile} />}
      {optionSelected === "Rooming" && <Rooming groupFile={groupFile} />}
      {optionSelected === "Tables" && <SeatingPlanScreen groupFile={groupFile} />}
      {optionSelected === "CourtshipLine" && <CourtshipLineScreen groupFile={groupFile} />}
      {optionSelected === "Transport" && <TransportScreen groupFile={groupFile} />}
      {optionSelected === "PaymentScheme" && <AgencyPaymentScheme groupFile={groupFile} />}
      {optionSelected === "WeedingPass" && <WeedingPass groupFile={groupFile} />}
      {optionSelected === "WaitingList" && <WaitingList groupFile={groupFile} />}

    </div>
  )
}
