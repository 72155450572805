import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getAirlinesList } from '../../../store/slices/catalogue/thunks';
import { AirlineTable } from './AirlineTable';
import { AddAirline } from './AddAirline';

export const AirlineScreen = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  
  useEffect(() => {
    dispatch(getAirlinesList())
  }, [])

  return (
    <div>

      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" 
          onClick={() => setAdd(true)}>Agregar Aerolinea</button>
        <AddAirline isOpen={add} setIsOpen={setAdd} />
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Aerolineas</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <AirlineTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
