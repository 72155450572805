import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGuestHighlighted, setFullGuestList, setGuestList, setSeatingPlan, setWeedingPassList, 
  setFullWeedingPassList, setExternalGuestHighlighted } from "../../../store/slices/group/groupSlice";
import { getFullExternalGuestList, getFullGuestList, getGuestList, getSeatingPlan, getWeedingPassList } from "../../../store/slices/group/thunks";
import { AddTable } from "./AddTable";
import { SeatingPlanDetails } from "./SeatingPlanDetails";
import styles from './SeatingPlanScreen.module.css'
import { SearchGuest } from "./SearchGuest";


export const SeatingPlanScreen = () => {
  const { info, seatingPlan, fullGuestList, fullWeedingPassList } = useSelector((state) => state.group);
  const [addTable, setAddTable] = useState(false);
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuestList(info.code));

    return () => {
      dispatch(setGuestList([]));
    }
  }, [])

  useEffect(() => {
    dispatch(getFullGuestList(info.code));

    return () => {
      dispatch(setFullGuestList([]));
    }
  }, [])

  useEffect(() => {
    dispatch(getWeedingPassList(info.code));

    return () => {
      dispatch(setWeedingPassList([]));
    }
  }, [])

  useEffect(() => {
    dispatch(getFullExternalGuestList(info.code));

    return () => {
      dispatch(setFullWeedingPassList([]));
    }
  }, [])


  useEffect(() => {
    dispatch(getSeatingPlan(info.code));

    return () => {
      dispatch(setSeatingPlan([]));
    }
  }, [])

  useEffect(() => {

    return () => {
      dispatch(setGuestHighlighted(0));
      dispatch(setExternalGuestHighlighted(0));
    }
  }, [])


  return (
    <div className={`${styles.mainDiv}`} >
      <div className="row mb-6">
        <div className="col-8">
          <div className="row">
            <div className="col-auto">
              <div className={`${styles.title}`}>Plan De Mesas</div>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary btn-info"
                onClick={() => setAddTable(true)}>Agregar Mesa</button>
            </div>
          </div>
        </div>
        <div className="col-4">
          <button className="btn btn-primary btn-info float-right"
            onClick={() => setSearch(true)}>Buscar Invitado</button>
        </div>
      </div>

      <SeatingPlanDetails seatingPlan={seatingPlan} code={info.code} idGroupFile={info.id} />
      <AddTable idGroupFile={info.id} code={info.code} isOpen={addTable} setIsOpen={setAddTable} />
      <SearchGuest fullGuestList={fullGuestList} fullWeedingPassList={fullWeedingPassList} code={info.code} isOpen={search} setIsOpen={setSearch} />
    </div>
  )
}
