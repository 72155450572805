import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useDispatch } from 'react-redux';
import { addAgencyPaymentScheme, addWeedingPass } from "../../../store/slices/groupFiles/thunks";
import { useState } from "react";
import { MySelect } from "../../ui/controls/MySelect";
import { MyFileInput } from "../../ui/controls/MyFileInput";
import { Image_Type } from "../../../const/Image";
import Swal from "sweetalert2";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";
import { MyCheckBox3 } from "../../ui/controls/MyCheckBox3";

export const AddWeedingPass = ({ groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState([
    {
      "value": "Pendiente",
      "label": "Pendiente"
    },
    {
      "value": "Pagado",
      "label": "Pagado"
    }
  ])

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Weeding Pass
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: "",
            birthdate: undefined,
            amount: "",
            image: undefined,
            status: undefined,
            stayAtHotel: false
          }}
          onSubmit={(({ name, birthdate, amount, status, image2, stayAtHotel
          }, { resetForm, setErrors }) => {

            if (!birthdate) {
              return setErrors({ "birthdate": "Requerido" })
            }

            if (!status) {
              return setErrors({ "status": "Requerido" })
            }

            let birthdate2;
            if (birthdate.year) {
              birthdate2 = birthdate.year + "-" + (birthdate.monthIndex + 1) + "-" + birthdate.day
            } else {
              birthdate2 = birthdate.getFullYear() + "-" + (birthdate.getMonth() + 1) + "-" + birthdate.getDate()
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addWeedingPass(groupFile.id, name, birthdate2, amount, status.value, image2, 
              (stayAtHotel || stayAtHotel === 1 ? 1 : 0), success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              amount: Yup.string()
                .required("Requerido"),

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de nacimiento:*' name="birthdate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("birthdate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Costo:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Estado:*' name="status" options={status} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("status", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <MyFileInput label='Comprobante' name='image' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 2000000) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Solo se permiten imagenes de 2MB max", "error");
                            }
                            setFieldValue("image2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox3 label="¿Se queda en el hotel?:" name="stayAtHotel" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
