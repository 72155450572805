import { ErrorMessage, useField } from "formik"
import styles from './MyTextArea.module.css'

export const MyTextArea2 = ({ label, mode="vertical", ...props }) => {

  const [field] = useField(props)

  return <>
    {
      mode === "horizontal" &&
      <div className="mb-3 row">
        <label className='col-form-label col-2' htmlFor={props.id || props.name} >{label}</label>
        <div className="col-10">
          <textarea className="form-control" type="text" {...field} {...props} />
          <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
        </div>        
      </div>
    }
    {
      mode === "vertical" &&
      <div className="mb-3">
        <label className='form-label' htmlFor={props.id || props.name} >{label}</label>
        <textarea className="form-control" type="text" {...field} {...props} />
        <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
      </div>
    }
  </>
}
