import React, { useState } from 'react'
import { AccountsPayableDetails } from './AccountsPayableDetails';

export const AccountsPayable = ({masterSaleGroup, groupFile, ref2}) => {

  return (
    <div style={{marginTop:40}} ref={ref2}>
      <div style={{ fontSize: 20}}>Cuentas por pagar a mayoristas</div>
      <AccountsPayableDetails masterSaleGroup={masterSaleGroup} />
    </div>
  )
}
