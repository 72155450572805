import React, { useState } from 'react'
import { PaymentToWholesalerDetails } from './PaymentToWholesalerDetails';
import { Table } from "react-bootstrap";
import { currencyFormat } from '../../../helpers/numericFields';

export const MoneyFlow = ({ masterSaleGroup, ref2 }) => {

  return (
    <div style={{ marginTop: 20 }} ref={ref2}>
      <div style={{ fontSize: 20 }}>Flujo de dinero</div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Total</th>
                <th>Anticipos</th>
                <th>Pagos clientes</th>
                <th>Ingresos</th>
                <th>Pagos a mayoristas</th>
                <th>Disponibles</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {currencyFormat(
                    masterSaleGroup.rooms.reduce((a, b) => a += b.publicPriceTotal, 0) +
                    masterSaleGroup.services.reduce((a, b) => a += b.publicPriceTotal, 0)
                  )}
                </td>
                <td>
                  {currencyFormat(masterSaleGroup.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0))}
                </td>
                <td>
                  {currencyFormat(masterSaleGroup.customerPayments.reduce((a, b) => a += b.amount, 0))}
                </td>
                <td>
                  {currencyFormat(
                    masterSaleGroup.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0) +
                    masterSaleGroup.customerPayments.reduce((a, b) => a += b.amount, 0)
                  )}
                </td>
                <td>{
                  currencyFormat(
                    masterSaleGroup.paymentsToWholesalerRooms.reduce((a, b) => a += b.amount, 0) +
                    masterSaleGroup.paymentsToWholesalerServices.reduce((a, b) => a += b.amount, 0)
                  )}
                </td>
                <td>{
                  currencyFormat(
                    (masterSaleGroup.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0) +
                      masterSaleGroup.customerPayments.reduce((a, b) => a += b.amount, 0)) -
                    (masterSaleGroup.paymentsToWholesalerRooms.reduce((a, b) => a += b.amount, 0) +
                      masterSaleGroup.paymentsToWholesalerServices.reduce((a, b) => a += b.amount, 0))
                  )}
                </td>
                <td>{
                  currencyFormat(                  
                    (masterSaleGroup.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0) +
                      masterSaleGroup.customerPayments.reduce((a, b) => a += b.amount, 0)) -
                    (masterSaleGroup.rooms.reduce((a, b) => a += b.publicPriceTotal, 0) +
                    masterSaleGroup.services.reduce((a, b) => a += b.publicPriceTotal, 0))
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}
