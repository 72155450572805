import { createSlice } from '@reduxjs/toolkit';

const initialMasterSale = {
  air: [],
  lodging: [],
  cruise: [],
  service: [],
  accountsPayable: [],
  advancePayments: [],
  rejectedPayments: [],
  paymentsToWholesalerServices: [],
  paymentsToWholesalerAir: [],
  paymentsToWholesalerLodging: [],
  paymentsToWholesalerCruise: [],
  paymentsToWholesalerService: [],
  isOpen:1,
}

export const filesSlice = createSlice({
  name: 'files',
  initialState: {    
    filesList: [],
    file: {},
    passengersList: [],
    masterSale: initialMasterSale,
    optionSelected: "Quotes",
    pendingPayments: [],
    pendingSpecialRequest: [],
  },
  reducers: {
   
    setFilesList: (state, action) => {
      state.filesList = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setPassengersList: (state, action) => {
      state.passengersList = action.payload;
    },
    setMasterSale: (state, action) => {
      state.masterSale = action.payload;
    },   
    resetMasterSale: (state, action) => {
      state.masterSale = initialMasterSale;
    }, 
    setOptionSelected: (state, action) => {
      state.optionSelected = action.payload;
    },
    setPendingPayments: (state, action) => {
      state.pendingPayments = action.payload;
    },
    setPendingSpecialRequest: (state, action) => {
      state.pendingSpecialRequest = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setFilesList, setFile, setPassengersList, setMasterSale, setOptionSelected, resetMasterSale,
  setPendingPayments, setPendingSpecialRequest} = filesSlice.actions;