import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import { setQuote, setQuotesList } from "./quotesSlice";
import fileDownload from 'js-file-download'

export const addQuote = (idFile) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addQuote", { idFile });
      dispatch(getQuotesListByIdFile(idFile))
    } catch (error) {
      
    }

  }
}

export const getQuotesListByIdFile = (idFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/quotes/byIdFile", {
        params: {
          idFile
        }
      });
      dispatch(setQuotesList(resp.data.results));
    } catch (error) {
      dispatch(setQuotesList([]));
    }
  }
}


export const editQuoteDescription = (id, description, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/quotes/editDescription", { id, description });
      dispatch(getQuoteById(id));
      success();
      Swal.fire("Cotizaciones", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Cotizaciones", error.response.data.msg, "error");
    }
  }
}

export const addQuoteToSale = (id, accepted) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/quotes/addQuoteToSale", { id, accepted });
      dispatch(getQuoteById(id));
      Swal.fire("Cotizaciones", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Cotizaciones", error.response.data.msg, "error");
    }

  }
}

export const addAirQuote = (idQuote, departureDate, returnDate, idAirline, idWholesaler, outboundItinerary,
  returnItinerary, rate, fee, idCurrency, notes, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addAirQuote", {
        idQuote, departureDate, returnDate, idAirline, idWholesaler, outboundItinerary,
        returnItinerary, rate, fee, idCurrency, notes
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const getQuoteById = (idQuote) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/quotes/byId", {
        params: {
          idQuote
        }
      });
      dispatch(setQuote(resp.data.results));
    } catch (error) {
      dispatch(setQuote({}));
    }
  }
}

export const editAirQuote = (id, departureDate, returnDate,idAirline, idWholesaler, outboundItinerary,
  returnItinerary, rate, fee, idCurrency, notes, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editAirQuote", {
        id, departureDate, returnDate, idAirline, idWholesaler, outboundItinerary,
        returnItinerary, rate, fee, idCurrency, notes
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteAirQuote = (id, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteAirQuote", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}


export const addLodgingQuote = (idQuote, checkInDate, checkOutDate, idHotel, idWholesaler,
  idCurrency, notes, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addLodgingQuote", {
        idQuote, checkInDate, checkOutDate, idHotel, idWholesaler,
        idCurrency, notes,
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const editLodgingQuote = (id, checkInDate, checkOutDate, idHotel, idWholesaler,
  idCurrency, notes, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editLodgingQuote", {
        id, checkInDate, checkOutDate, idHotel, idWholesaler, idCurrency, notes
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteLodingQuote = (id, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteLodgingQuote", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const addRoomLodging = (idLodging, room, rate, netPrice, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addRoomLodging", {
        idLodging, room, rate, netPrice
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const editRoomLodging = (id, room, rate, netPrice, idQuote, success) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editRoomLodging", {
        id, room, rate, netPrice
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteRoomLodging = (id, idQuote, success) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteRoomLodging", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const addCruiseQuote = (idQuote, departureDate, returnDate, company, idWholesaler, ship, port,
  idCurrency, notes, route, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addCruiseQuote", {
        idQuote, departureDate, returnDate, company, idWholesaler, ship, port,
        idCurrency, notes, route,
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
      console.log(error.response)
    }
  }
}

export const editCruiseQuote = (id, departureDate, returnDate, company, idWholesaler, ship, port, route,
  notes, idCurrency, idQuote, success) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editCruiseQuote", {
        id, departureDate, returnDate, company, idWholesaler, ship, port, route, notes, idCurrency
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteCruiseQuote = (id, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteCruiseQuote", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const addCabinCruise = (idCruise, cabin, rate, netPrice, tax, tip, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addCabinCruise", {
        idCruise, cabin, rate, netPrice, tax, tip
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const editCabinCruise = (id, cabin, rate, netPrice, tax, tip, idQuote, success) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editCabinCruise", {
        id, cabin, rate, netPrice, tax, tip
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteCabinCruise = (id, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteCabinCruise", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const addServiceQuote = (idQuote, startDate, endDate, service, idWholesaler, details, rate,
  netPrice, idCurrency, notes, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addServiceQuote", {
        idQuote, startDate, endDate, service, idWholesaler, details, rate, netPrice,
        idCurrency, notes,
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
      console.log(error.response)
    }
  }
}

export const editServiceQuote = (id, startDate, endDate, service, idWholesaler, details, rate,
  netPrice, idCurrency, notes, idQuote, success) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/quotes/editServiceQuote", {
        id, startDate, endDate, service, idWholesaler, details, rate, netPrice, idCurrency, notes,
      });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}

export const deleteServiceQuote = (id, idQuote, success) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.delete("/quotes/deleteServiceQuote", { params: { id } });
      success();
      dispatch(getQuoteById(idQuote))
    } catch (error) {
    }
  }
}


export const addIndividualQuote = (idIndividualFile) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/quotes/addIndividualQuote", { idIndividualFile });
      dispatch(getQuotesListByIdIndividualFile(idIndividualFile))
    } catch (error) {
    }

  }
}

export const getQuotesListByIdIndividualFile = (idIndividualFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/quotes/byIdIndividualFile", {
        params: {
          idIndividualFile
        }
      });
      dispatch(setQuotesList(resp.data.results));
    } catch (error) {
      dispatch(setQuotesList([]));
    }
  }
}

export const quoteSummary = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/quotes/quoteSummary", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Cotizacion ${id}.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}