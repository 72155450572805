import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getUsersList } from '../../../store/slices/catalogue/thunks';
import { AddUser } from './AddUser';
import { UserTable } from './UserTable';

export const UserScreen = () => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const [add, setAdd] = useState(false);

  useEffect(() => {
    if (role == "DIRECTOR") {
      dispatch(getUsersList())
    }
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-end" style={{ marginTop: 20, marginBottom: 20 }} >
        {
          (role == "DIRECTOR") &&
          <><button className="btn btn-primary btn-icon text-white"
            onClick={() => setAdd(true)}>Agregar Usuario</button>
            <AddUser isOpen={add} setIsOpen={setAdd} />
          </>
        }
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Usuarios</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <UserTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
