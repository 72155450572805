import { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';
import { EditService } from "./EditService";

export const ServiceTable = () => {
  const { servicesList } = useSelector((state) => state.catalogue);
  const [edit, setEdit] = useState(false)
  const [item, setItem] = useState({});

  const edit2 = (item) => {
    setEdit(true);
    setItem(item)
  }

  const columns = [
    {
      name: "Nombre",
      selector: row => [row.name],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.name}</span>
    },
    {
      name: "Estatus",
      selector: row => [row.status],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.status === 1 ? "Activo" : "Inactivo"}</span>
    },
    {
      name: "Editar",
      width: "85px",
      cell: row => <span className="text-center w-100">
        <i className={`fe fe-edit .hover`} role='button' onClick={() => edit2(row)}></i>
      </span>
    },
  ]
  const tableDatas = {
    columns,
    data: servicesList,
  };
  return (
    <>
      <DataTableExtensions {...tableDatas} >
        <DataTable
          columns={columns}
          data={servicesList}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          striped={true}
          center={true}
          persistTableHead
          pagination
          highlightOnHover />
      </DataTableExtensions>
      <EditService item={item} isOpen={edit} setIsOpen={setEdit} />
    </>
  )
}