import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../ui/controls/MySelect";
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentWayList } from "../../store/slices/general/thunks";
import { Image_Type } from "../../const/Image"
import Swal from "sweetalert2";
import { MyFileInput29 } from "../ui/controls/MyFileInput29";
import { addPaymentMasterSale } from "../../store/slices/group/thunks";

export const AddAdvancePayment = ({ masterSale, code, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { paymentMethodList, paymentWayList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Mandar Pago Realizado
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            amount: "",
            paymentMethod: undefined,
            paymentWay: undefined,
            notes: "",
            image: undefined,
          }}
          onSubmit={(({ amount, paymentMethod, paymentWay, notes, image2
          }, { resetForm, setErrors }) => {

            if (!paymentMethod) {
              return setErrors({ "paymentMethod": "Requerido" })
            }
            if (!paymentWay) {
              return setErrors({ "paymentWay": "Requerido" })
            }
            if(paymentMethod.label !== "EFECTIVO" && !image2){              
              return setErrors({ "image": "Requerido" })              
            }

            let date2 = new Date();
            let date = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();

            const success = () => {
              resetForm();
              setIsOpen(false)
            }
            dispatch(addPaymentMasterSale(masterSale.id, date, amount, paymentWay.value, 
              image2, notes, code, success));
          })}
          validationSchema={
            Yup.object({
              amount: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Monto:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Metodo:*' name="paymentMethod" options={paymentMethodList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentMethod", e);
                            setFieldValue("paymentWay", null);
                            dispatch(getPaymentWayList(e.value))
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Forma de pago:*' name="paymentWay" options={paymentWayList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentWay", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <MyFileInput29 label='Evidencia' name='image' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 2000000) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Solo se permiten imagenes de 2MB max", "error");
                            }
                            setFieldValue("image2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
