import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../ui/controls/MySelect";
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { useSelector, useDispatch } from 'react-redux';
import { addCruiseQuote } from "../../store/slices/quotes/thunks";

export const AddCruise = ({ quote, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { currenciesList, wholesalersList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Crucero A Cotizacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            departureReturnDate: [quote.startDate, quote.endDate],
            company: '',
            wholesaler: undefined,
            ship: '',
            port: '',
            currency: { value: 1, label: "MXN" },
            notes: '',
            route: '',
          }}
          onSubmit={(({ departureReturnDate, company, wholesaler, ship, port,
            currency, notes, route }, { resetForm, setErrors }) => {

            let departureDate;
            if (departureReturnDate[0].year) {
              departureDate = departureReturnDate[0].year + "-" + (departureReturnDate[0].monthIndex + 1) + "-" + departureReturnDate[0].day
            } else {
              const temp = departureReturnDate[0].split("-")
              departureDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let returnDate;
            if (departureReturnDate[1].year) {
              returnDate = departureReturnDate[1].year + "-" + (departureReturnDate[1].monthIndex + 1) + "-" + departureReturnDate[1].day
            } else {
              const temp = departureReturnDate[1].split("-")
              returnDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!departureReturnDate || departureReturnDate.length !== 2) {
              return setErrors({ "departureReturnDate": "Requerido" })
            }
            if (!wholesaler) {
              return setErrors({ "wholesaler": "Requerido" })
            }
            if (!currency) {
              return setErrors({ "currency": "Requerido" })
            }


            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addCruiseQuote(quote.id, departureDate, returnDate, company, wholesaler.value, ship, port,
              currency.value, notes, route, success));
          })}
          validationSchema={
            Yup.object({
              company: Yup.string()
                .required("Requerido"),
              ship: Yup.string()
                .required("Requerido"),
              port: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Salida y Regreso:*' name="departureReturnDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("departureReturnDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-0"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Naviera:*' name='company' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:*' name="wholesaler" options={wholesalersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Barco:*' name='ship' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Puerto:*' name='port' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Ruta:' name='route' mode='horizontal' rows={2} />
                      </div>
                    </div>                    
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Divisa:*' name="currency" options={currenciesList} mode='horizontal'
                          defaultValue={{ value: 1, label: "MXN" }}
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("currency", e);
                          }} />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
