import React, { useEffect } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getRepresentativesList, } from '../../store/slices/general/thunks';
import { FormSearchRepresentative } from './FormSearchRepresentative';
import { SearchTable } from './SearchTable';


export const RepresentativeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRepresentativesList());
  }, [])

  return (
    <div>

      <FormSearchRepresentative />
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Agente</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <SearchTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
