import { Table } from "react-bootstrap";
import { currencyFormat } from "../../helpers/numericFields";
import styles from './MasterSaleDetails.module.css'

export const MasterSaleDetails = ({ masterSale }) => {

  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Fechas</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {
                masterSale.groupRooms.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>{item.quantity}</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                  </tr>))
              }
              {
                masterSale.services.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>{item.quantity}</td>
                    <td>{item.service}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>                    
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                  </tr>))
              }
              {
                masterSale.air.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.airline}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>                    
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.lodging.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.cruise.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.company} - {item.cabin}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.service.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.service}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <div className={styles.total}>Total: {
          currencyFormat(
            masterSale.groupRooms.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.services.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.air.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.lodging.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.cruise.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.service.reduce((a, b) => a += (b.publicPriceTotal), 0) 
          )}
        </div>
      </div>
    </>
  )
}
