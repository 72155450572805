import React, { useState } from 'react'
import { PaymentToWholesalerDetails } from './PaymentToWholesalerDetails';

export const PaymentToWholesaler = ({masterSale}) => {

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos a mayoristas</div>

      <PaymentToWholesalerDetails masterSale={masterSale} />
    </div>
  )
}
