import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../ui/controls/MySelect";
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegers } from "../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { addLodgingQuote } from "../../store/slices/quotes/thunks";
import moment from 'moment'
import { MyTextLabel } from "../ui/controls/MyTextLabel";

export const AddLodging = ({ quote, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { currenciesList, wholesalersList, hotelsList } = useSelector((state) => state.general);
  const [daysNights, setDaysNights] = useState({ days: 0, nights: 0 });

  useEffect(() => {
    const date1 = new moment(quote.startDate, "DD-MM-YYYY")
    const date2 = new moment(quote.endDate, "DD-MM-YYYY")

    setDaysNights({ days: date2.diff(date1, 'days') + 1, nights: date2.diff(date1, 'days') })
  }, [isOpen])

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Hotel A Cotizacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            checkInCheckOutDate: [quote.startDate, quote.endDate],
            hotel: undefined,
            wholesaler: undefined,
            currency: { value: 1, label: "MXN" },
            notes: '',
          }}
          onSubmit={(({ checkInCheckOutDate, hotel, wholesaler, currency, notes }, { resetForm, setErrors }) => {

            let checkInDate;
            if (checkInCheckOutDate[0].year) {
              checkInDate = checkInCheckOutDate[0].year + "-" + (checkInCheckOutDate[0].monthIndex + 1) + "-" + checkInCheckOutDate[0].day
            } else {
              const temp = checkInCheckOutDate[0].split("-")
              checkInDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let checkOutDate;
            if (checkInCheckOutDate[1].year) {
              checkOutDate = checkInCheckOutDate[1].year + "-" + (checkInCheckOutDate[1].monthIndex + 1) + "-" + checkInCheckOutDate[1].day
            } else {
              const temp = checkInCheckOutDate[1].split("-")
              checkOutDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!checkInCheckOutDate || checkInCheckOutDate.length !== 2) {
              return setErrors({ "checkInCheckOutDate": "Requerido" })
            }
            if (!hotel) {
              return setErrors({ "hotel": "Requerido" })
            }
            if (!currency) {
              return setErrors({ "currency": "Requerido" })
            }


            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addLodgingQuote(quote.id, checkInDate, checkOutDate, hotel.value, wholesaler ? wholesaler.value : "",
              currency.value, notes, success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Ida y Vuelta:*' name="checkInCheckOutDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("checkInCheckOutDate", e);
                            if (!e || e.length === 2) {
                              const date1 = new moment(e[0].day + "-" + (e[0].monthIndex + 1) + "-" + e[0].year, "DD-MM-YYYY")
                              const date2 = new moment(e[1].day + "-" + (e[1].monthIndex + 1) + "-" + e[1].year, "DD-MM-YYYY")
                              setDaysNights({ days: date2.diff(date1, 'days') + 1, nights: date2.diff(date1, 'days') })
                            }
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-0"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hotel:*' name="hotel" options={hotelsList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("hotel", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:' name="wholesaler" options={wholesalersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Dias:' mode='horizontal' value={daysNights.days} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Noches:' mode='horizontal' value={daysNights.nights} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Divisa:*' name="currency" options={currenciesList} mode='horizontal'
                          defaultValue={{ value: 1, label: "MXN" }}
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("currency", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
