import { useState } from "react";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from './Passengers.module.css'
import { AddPassenger } from "./AddPassenger";
import { getPassengersByIdFile } from "../../../store/slices/files/thunks";
import { EditPassenger } from "./EditPassenger";

export const Passengers = ({ file }) => {
  const dispatch = useDispatch();
  const { passengersList } = useSelector((state) => state.files);
  const [editPassenger, setEditPassenger] = useState(false);
  const [addPassenger, setAddPassenger] = useState(false);
  const [passengerSelected, setPassengerSelected] = useState({});

  useEffect(() => {
    if (file.id) {
      dispatch(getPassengersByIdFile(file.id));
    }
  }, [file.id])

  const editPassenger2 = (passenger) => {
    setEditPassenger(true);
    setPassengerSelected(passenger)
  }

  return (
    <>
      <button className="btn btn-light" style={{ marginTop: 20, marginBottom: 20 }}
        onClick={() => setAddPassenger(true)} >Agregar Pasajero</button>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pasajero</th>
              <th>Parentesco</th>
              <th>Fecha Nacimiento</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              passengersList.map(passenger => (
                <tr key={passenger.id}>
                  <td>{passenger.name} {passenger.lastName} {passenger.mothersLastName}</td>
                  <td>{passenger.relationship}</td>
                  <td>{passenger.birthdate}</td>
                  <td style={{textAlign:"center"}}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() =>editPassenger2(passenger)}></i>                    
                  </td>
                </tr>
              ))
            }

          </tbody>
        </Table>
      </div>

      <AddPassenger file={file} isOpen={addPassenger} setIsOpen={setAddPassenger} />
      <EditPassenger passenger={passengerSelected} isOpen={editPassenger} setIsOpen={setEditPassenger} />
    </>
  )
}
