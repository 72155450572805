import React, { useEffect, useState } from 'react'
import styles from './PublicMenuScreen.module.css'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/brand/logoAccess.png'
import { Contact } from '../Contact/Contact';

export const PublicMenuScreen = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.mainDiv}>
      <div></div>
      <div className={styles.form}>
        <div className={styles.divLogo}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <label className={`${styles.title} form-label mb-3 text-center`}>Elija una opción</label>
        <div className={`mb-2 mt-5`}>
          <div className='row text-center'>
            <div className='col-4'>
              <button className={`btn btn-primary`} style={{ width: 100 }}
                onClick={() => navigate("/viaje")} >
                Viajes
              </button>
            </div>
            <div className='col-4'>
              <button className={`btn btn-primary`} style={{ width: 100 }}
                onClick={() => navigate("/invitado")} >
                Invitados
              </button>
            </div>
            <div className='col-4'>
              <button className={`btn btn-primary`} style={{ width: 100 }}
                onClick={() => navigate("/grupo")} >
                Grupos
              </button>
            </div>
          </div>
        </div>
      </div>      
      <Contact />      
    </div>
  )
}
