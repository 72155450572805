import { Table } from "react-bootstrap";
import styles from './Summary.module.css'

export const SummaryDetails = ({roomingList}) => {

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Hotel</th>
              <th>Cantidad</th>
              <th>Habitacion</th>
              <th>Adultos</th>
              <th>Juniors</th>
              <th>Menores</th>
              <th>Infantes</th>
            </tr>
          </thead>
          <tbody>
            {
              roomingList.summary.map(item => (
                <tr key={item.hotel + item.room}>
                  <td>{item.hotel}</td>
                  <td>{item.quantity}</td>
                  <td>{item.room}</td>
                  <td>{item.adults}</td>
                  <td>{item.juniors}</td>
                  <td>{item.minors}</td>
                  <td>{item.infants}</td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}
