import { ErrorMessage, useField } from "formik"
import styles from './MySelect.module.css'
import Select from 'react-select'

export const MySelect = ({ label, mode = "vertical", ...props }) => {

  const [field] = useField(props)

  return <>
    {
      mode === "horizontal" &&
      <div className="mb-3 row">
        <label className='col-form-label col-4' htmlFor={props.id || props.name} >{label}</label>
        <div className="col-8">
          <Select className={styles.select} displayValue="key" placeholder="Selecciona..." {...field} {...props} />
          <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
        </div>
      </div>
    }
    {
      mode === "vertical" &&
      <div className="mb-3">
        <label className='form-label' htmlFor={props.id || props.name} >{label}</label>
        <Select className={styles.select} displayValue="key" placeholder="Selecciona..." {...field} {...props} />
        <ErrorMessage name={props.name} component="span" className={styles.errorMessage} />
      </div>
    }
  </>
}
