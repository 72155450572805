import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addContract } from "../../../store/slices/files/thunks";
import { MyFileInput } from "../../ui/controls/MyFileInput";
import Swal from "sweetalert2";

export const AddContract = ({ file, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Contrato
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            file: undefined,
          }}
          onSubmit={(({ file2 }, { resetForm, setErrors }) => {

            if (!file2) {
              return setErrors({ "file": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addContract(file.id, file2,  success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <MyFileInput label='Archivo' name='file' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (e.target.files[0].type !== "application/pdf") {
                              setFieldValue("file2", undefined)
                              return Swal.fire("Contrato", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 5000000) {
                              setFieldValue("file2", undefined)
                              return Swal.fire("Contrato", "Solo se permiten contratos de 5MB max", "error");
                            }
                            setFieldValue("file2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Contrato
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
