import { useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MySelect } from "../ui/controls/MySelect";
import { useSelector, useDispatch } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import '../../assets/css/reactTags.css'
import '../../assets/css/react-select-search.css'
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegers, onlyIntegersDecimals } from "../../helpers/numericFields";
import { MyCheckBox } from "../ui/controls/MyCheckBox";
import { addGroupFile } from "../../store/slices/groupFiles/thunks";
import moment from 'moment'
import { MyTextLabel } from "../ui/controls/MyTextLabel";
import { MyTextInput66 } from "../ui/controls/MyTextInput66";
import { MyTextLabel66 } from "../ui/controls/MyTextLabel66";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const AddGroupFile = ({ groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { additionalList } = useSelector((state) => state.groupFiles);
  const { customersList, categoriesList } = useSelector((state) => state.general);
  const [tags, setTags] = useState([]);
  const [daysNights, setDaysNights] = useState({ days: 0, nights: 0 });
  const [tag, setTag] = useState({});

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTag({})
    setTags([...tags, tag]);
  };

  const handleAdditionButton = tag => {
    if (tag.id) {      
      const item = tags.find(x => x.id === tag.id);      
      if (!item) {
        setTag({})
        handleAddition(tag)
      }
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {

  };

  const searchInput = useRef(null);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Crear Grupo
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            idCustomer: undefined,
            name: '',
            idCategory: undefined,
            tags: '',
            lodging: '',
            startEndDate: '',
            paydayLimit: '',
            passengers: "",
            rooms: "",
            notes: '',
            peopleQuantity: "",
            additionalPersonPrice: "",
            weddingPassPrice: "",
            suplementPrice: "",
          }}
          onSubmit={(({ idCustomer, name, tags: tags2, idCategory, lodging, startEndDate, paydayLimit,
            passengers, rooms, peopleQuantity, additionalPersonPrice, weddingPassPrice, suplementPrice,
              notes, ...additional },
            { resetForm, setErrors }) => {

            if (!idCustomer) {
              return setErrors({ "idCustomer": "Requerido" })
            }
            if (!idCategory) {
              return setErrors({ "idCategory": "Requerido" })
            }
            if (tags === "" || tags.length === 0) {
              return setErrors({ "tags": "Requerido" })
            }
            if (!startEndDate || startEndDate.length !== 2) {
              return setErrors({ "startEndDate": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false);
              setTags([])
            }

            const newAdditional = Object.keys(additional).map(item => {
              if (additional[item]) return item
            })
            const newAdditionalList = newAdditional.filter(x => x)

            dispatch(addGroupFile(idCustomer.value, idCategory.value, name, tags.map(item => item.text).join(", "),
              startEndDate[0].year + "-" + (startEndDate[0].monthIndex + 1) + "-" + startEndDate[0].day,
              startEndDate[1].year + "-" + (startEndDate[1].monthIndex + 1) + "-" + startEndDate[1].day,
              paydayLimit ? paydayLimit.year + "-" + (paydayLimit.monthIndex + 1) + "-" + paydayLimit.day : undefined,
              lodging, passengers, rooms, newAdditionalList, peopleQuantity, additionalPersonPrice, 
              weddingPassPrice, suplementPrice, notes, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Cliente:*' name="idCustomer" options={customersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("idCustomer", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Categoria:*' name="idCategory" options={categoriesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("idCategory", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <div className="mb-3 row">
                          <label className='col-form-label col-4' htmlFor="destinations" >Destinos:*</label>
                          <div className="col-7">
                            <ReactTags
                              tags={tags}
                              placeholder=""
                              delimiters={delimiters}
                              handleDelete={handleDelete}
                              handleAddition={handleAddition}
                              handleDrag={handleDrag}
                              handleTagClick={handleTagClick}
                              handleInputChange={(e) => setTag({ id: e, text: e })}
                              inputFieldPosition="top"
                              autocomplete
                            />
                            <ErrorMessage name="tags" component="span" style={{ color: "red" }} />
                          </div>
                          <div className="col-1">
                            <Button onClick={() => handleAdditionButton(tag)}>+</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Alojamiento(s):' name='lodging' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fechas Viaje:*' name="startEndDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("startEndDate", e);
                            if (!e || e.length === 2) {
                              const date1 = new moment(e[0].day + "-" + (e[0].monthIndex + 1) + "-" + e[0].year, "DD-MM-YYYY")
                              const date2 = new moment(e[1].day + "-" + (e[1].monthIndex + 1) + "-" + e[1].year, "DD-MM-YYYY")
                              setDaysNights({ days: date2.diff(date1, 'days') + 1, nights: date2.diff(date1, 'days') })
                            }
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha limite de pago:' name="paydayLimit" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("paydayLimit", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Huespedes:' name='passengers' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("passengers", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Habitaciones:' name='rooms' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("rooms", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label='Noches:' mode='horizontal' value={daysNights.nights} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel66 label='Dias:' mode='horizontal' value={daysNights.days} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Personas en el paquete:' name='peopleQuantity' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("peopleQuantity", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo persona extra:' name='additionalPersonPrice' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("additionalPersonPrice", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo wedding pass:' name='weddingPassPrice' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("weddingPassPrice", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo suplemento:' name='suplementPrice' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("suplementPrice", e.target.value);
                          }} />
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-12"></div>
                    {
                      additionalList.map(item => (
                        <div className="col-lg-3 col-md-12" key={item.id}>
                          <div className=" row mb-4">
                            <MyCheckBox label={`${item.name}`} name={item.id} mode='horizontal' />
                          </div>
                        </div>
                      ))
                    }
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
