import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../helpers/numericFields";
import { groupReservationVoucher } from "../../store/slices/group/thunks";
import { ModalImage } from "../ui/controls/ModalImage";
import styles from './AdvancePaymentDetails.module.css'
import { useDispatch } from 'react-redux';

export const AdvancePaymentDetails = ({ masterSale, code }) => {
  const dispatch = useDispatch();
  const [viewImage, setViewImage] = useState(false);
  const [image, setImage] = useState("");

  const viewImage2 = (payment) => {
    setViewImage(true);
    setImage(payment.image)
  }

  const downloadGroupVoucher = (id) => {
    dispatch(groupReservationVoucher(id, code));
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Aprobado</th>
              <th>Recibo</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>
              <th>Referencia</th>
              <th>Evidencia</th>
              <th>Monto</th>
              <th>Notas</th>
            </tr>
          </thead>
          <tbody>
            {
              masterSale.advancePayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td style={{ textAlign: "center" }}>
                    {payment.approved === 1 ? <i className="fa fa-check" /> : ""}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.approved === 1
                        ? <i className={`fa fa-file-pdf-o ${styles.edit}`} onClick={() => downloadGroupVoucher(payment.id)}></i>
                        : ""
                    }
                  </td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>
                  <td>{payment.reference}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.image &&
                      <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(payment)}></i>
                    }
                  </td>
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <ModalImage image={image} isOpen={viewImage} setIsOpen={setViewImage} />
      <div className={styles.summary} style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
        <div>
          Total: {currencyFormat(masterSale.advancePayments.filter(x=>x.approved== 1).reduce((a, b) => a += b.amount, 0))}
        </div>        
      </div>      
    </div>
  )
}
