import React, { useEffect } from 'react'
import { FormSearchFile } from './FormSearchFile'
import { Col, Row, Card } from "react-bootstrap";
import { SearchTable } from './SearchTable';
import { AddCustomer } from '../Catalogue/Customer/AddCustomer';
import { useDispatch } from 'react-redux';
import { AddFile } from '../File/AddFile';
import { useState } from 'react';
import { getCustomersList, getFilterMonth, getCategoriesList, getContactWayList } from '../../store/slices/general/thunks';
import { setFilesList } from '../../store/slices/files/filesSlice';


export const SearchFileScreen = () => {
  const dispatch = useDispatch();
  const [addFile, setAddFile] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);

  useEffect(() => {
    dispatch(getFilterMonth());
  }, [])
  useEffect(() => {
    dispatch(getContactWayList());
  }, [])
  useEffect(() => {
    dispatch(getCategoriesList());
  }, [])
  useEffect(() => {
    dispatch(getCustomersList());
  }, [])
  
  useEffect(() => {  
    return () => {
      dispatch(setFilesList([]));
    }
  }, [])

  return (
    <div>

      <div className="d-flex justify-content-between" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className="btn btn-primary btn-icon text-white" onClick={() => setAddFile(true)}>Crear Expediente</button>
        <button className="btn btn-primary btn-icon text-white" onClick={() => setAddCustomer(true)}>Agregar Cliente</button>
        <AddFile isOpen={addFile} setIsOpen={setAddFile} />
        <AddCustomer isOpen={addCustomer} setIsOpen={setAddCustomer} />
      </div>

      <FormSearchFile />
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Expedientes</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <SearchTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
