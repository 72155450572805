import { useState } from "react";
import { useDispatch } from 'react-redux';
import styles from './Notes.module.css'
import { updateNotes } from "../../../../store/slices/individualFiles/thunks";

export const Notes = ({ individualFile }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(individualFile.notes);

  const save = () => {
    const success = () => {};
    dispatch(updateNotes(notes, individualFile.id, success));
  }

  return (
    <>

      <div className="col-lg-12 col-md-12">
        <div className=" row mb-4">
          <textarea className="form-control" type="text" rows="10" value={notes}
            onChange={ e => setNotes(e.target.value)}
          />
        </div>
      </div>
      <button className="btn btn-light" style={{ marginTop: 20, marginBottom: 20 }}
        onClick={() => save(true)} >Guardar</button>

    </>
  )
}
