import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';

export const SearchTable = () => {
  const { individualFilesList } = useSelector((state) => state.groupFiles);
  const columns = [
    {
      name: "Habitacion",      
      width:"85px",
      cell: row => <span className="fs-15 fw-semibold text-center w-100">{row.roomNum}</span>
    },
    {
      name: "Expediente",
      width:"85px",
      cell: row => <span className="text-center w-100">
        <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
          <Link to={`/panel/expedienteIndividual?id=${row.id}`} 
            className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{row.id}</span></Link>
        </OverlayTrigger>
      </span>
    },
    {
      name: "Fecha",      
      selector: row => [row.requestDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.requestDate}</span>
    },
    {
      name: "Estatus",
      selector: row => [row.status],
      sortable: true,
      cell: row => <span className={`text-${row.status ==="Saldo" ? "yellow" : "green" } fs-15 fw-semibold text-center w-100`}>{row.status}</span>
    },
    {
      name: "Agente",
      selector: row => [row.agent],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.agent}</span>
    },
    {
      name: "Medio",
      selector: row => [row.contactWay],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.contactWay}</span>
    },
    {
      name: "Cliente",
      selector: row => [row.customer],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.customer}</span>
    },
    {
      name: "Notas",
      selector: row => [row.notes],
      sortable: true,
      cell: row => <span className="text-center w-100">
        {row.notes && <i className="fa fa-check" />}
      </span>
    },
    {
      name: "Cotizaciones",
      selector: row => [row.quotes],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.quotes}</span>
    },
    {
      name: "Dueño",
      selector: row => [row.owner],
      sortable: true,
      cell: row => <span className="text-center w-100">
        {row.owner ? <i className="fa fa-check" /> : ""}
      </span>
    },
    {
      name: "Movil",
      selector: row => [row.cellphone],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.cellphone}</span>
    },
    {
      name: "Fecha Inicio",
      selector: row => [row.checkInDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.checkInDate}</span>
    },
    {
      name: "Fecha Fin",
      selector: row => [row.checkOutDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.checkOutDate}</span>
    }
  ]
  const tableDatas = {
    columns,
    data:individualFilesList,
  };
  return (
    <DataTableExtensions {...tableDatas} >
      <DataTable
        columns={columns}
        data={individualFilesList}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        striped={true}
        center={true}
        persistTableHead
        pagination
        highlightOnHover />
    </DataTableExtensions>
  )
}