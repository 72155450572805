import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { addService } from "../../../store/slices/catalogue/thunks";
import { useDispatch } from "react-redux";

export const AddService = ({isOpen, setIsOpen}) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Servicio
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={(({ name }, { resetForm, setErrors }) => {
            const success = ()=> {
              resetForm();
              setIsOpen(false)
            }
            
            dispatch(addService(name, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>                    
                  </Row>
                  <div style={{fontSize:13}}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
