import { createSlice } from '@reduxjs/toolkit';

export const travelerSlice = createSlice({
  name: 'traveler',
  initialState: {    
    info: {
      masterSale: {      
        air: [],
        lodging: [],
        cruise: [],
        service: [],
        advancePayments: [],
        rejectedPayments: [],
      },
    },   
    specialRequests: [], 
  },
  reducers: {   
    setInfo: (state, action) => {
      state.info = action.payload;
    },
    setSpecialRequests: (state, action) => {
      state.specialRequests = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setInfo, setMasterSale, setSpecialRequests
  } = travelerSlice.actions;